import axios from 'axios'
import {  DEFAULT_URL } from './api'

const config = {
    baseURL: DEFAULT_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

const axiosDefault = axios.create(config)



export default axiosDefault