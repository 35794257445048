import { useI18n } from "vue-i18n";

export function useSwal() {
    const { t } = useI18n({ useScope: 'global' });

    function showSwal(title, subtitle, icon="warning", color="#E51335") {
        return Swal.fire({
            title: `<h2 style="font-weight: 600; color: #333333;">${title ? title : 'Confirmation'}</h2>`,
            html: `
                <p style="font-size: 16px; color: #6c757d;">${subtitle}</p>
                ${icon != 'info' ? `<p style="font-size: 12px; color: red;">${t('swal.text')}</p>` : ''}
            `,
            icon: icon,
            iconColor: color,
            showCancelButton: true,
            cancelButtonText: t('swal.no'),
            confirmButtonText: t('swal.yes'),
            customClass: { 
                popup: 'custom-swal-modal',
                cancelButton: 'btn btn-sm btn-secondary bg-light text-dark px-4 order-1',
                confirmButton: 'btn btn-sm btn-primary text-white px-4 order-2'
            },
            confirmButtonColor: '#0072CE',
            cancelButtonColor: '#d33',
            buttonsStyling: false,
            width: '400px',
            padding: '20px',
            background: '#fff',
            showClass: {
                popup: 'swal2-show',
                backdrop: 'swal2-backdrop-show',
                icon: ''
            },
            hideClass: {
                popup: 'swal2-hide',
                backdrop: 'swal2-backdrop-hide',
                icon: ''
            },
            didOpen: () => {
                const confirmButton = document.querySelector(".swal2-confirm");
                const cancelButton = document.querySelector(".swal2-cancel");
                confirmButton.style.width = "140px";
                cancelButton.style.width = "140px";
                confirmButton.style.borderRadius = "8px";
                cancelButton.style.borderRadius = "8px";
                cancelButton.style.backgroundColor = "#F0F0F0";
                cancelButton.style.color = "#6C757D";
                confirmButton.style.color = "#FFFFFF";
                confirmButton.style.backgroundColor = "#0072CE";

                const iconElement = document.querySelector(".swal2-icon.swal2-warning");
                if (iconElement) {
                    iconElement.style.backgroundColor = color;
                    iconElement.style.color = "#FFFFFF";
                    iconElement.style.border = "none";
                }
            },
        });
    }

    return { showSwal };
}
