import * as types from './mutation-types'

export const loadAppointmentTypes = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/appointmentType/collection', data).then((response) => {
            commit(types.ADD_APPOINTMENT_TYPES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateAppointmentType = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/'+ data.id +'/appointmentType', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createAppointmentType = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/appointmentType', data).then((response) => {
            commit(types.ADD_APPOINTMENT_TYPE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const deleteAppointmentType = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/appointmentType').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

