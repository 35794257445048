<template>
    <div class="footer bg-white py-5 mt-auto" id="kt_footer" style="width : 100% !important">
        <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div class="text-dark order-2 order-md-1">
                  <span class="text-muted">{{ $t('powered') }}
                      <a class="text-muted text-hover-primary" target="_blank" href="https://netbyus.com">{{ $t('poweredby') }}</a>
                  </span>
              <span class="text-muted px-2" >©{{ new Date().getFullYear() }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>
