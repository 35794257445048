import * as types from './mutation-types'
import {COMPANY_INFO} from "./mutation-types";

export default {
  [types.RESET_CURRENT_USER] (state, user) {
    state.currentUser = null
  },
  [types.BOOTSTRAP_CURRENT_USER] (state, user) {
    state.currentUser = user
  },
  [types.UPDATE_CURRENT_USER] (state, user) {
    state.currentUser = user
  },
  [types.ADD_USER](state, data) {
    state.users.push(data)
  },
  [types.ADD_USERS](state, data) {
    state.users = data.users
  },
  [types.COMPANY_INFO](state, data) {
    state.companyInfo = {
      ...data?.infoGeneral?.tenant_information,
      country: data?.infoGeneral?.country
    }
  },

}
