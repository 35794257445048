import * as types from './mutation-types'

export default {
  [types.ADD_VARIATIONS](state, data) {
    state.variations = data.variations
  },

  [types.ADD_VARIATION](state, data) {
    state.variations.push(data)
  },
}
