import * as types from './mutation-types'

export default {

  [types.LOAD_DASHBOARD_STATS](state, data) {
    state.dashboardStats = {...data}
  },
  [types.LOAD_STATISTICS](state, data) {
    state.statistics = data
  },
  [types.LOAD_SALES_STATISTICS](state, data) {
    state.saleStatistics = data
  },
}

