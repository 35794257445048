import * as types from './mutation-types'


export default {
  [types.ADD_CITIES] (state, data) {
    state.cities = data
  },

  [types.ADD_CITY] (state, data) {
    state.cities.push(data)
  },

}
