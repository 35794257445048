<template>
  <div style="background: linear-gradient(rgba(0, 114, 206, 0.1), rgba(240, 120, 23, 0.1)), url('/assets/media/empty_bg.png') no-repeat center center fixed; background-size: cover; height: 100vh;">
    <div class="container">
      <div class="content">
        <span class="icon text-primary mb-10"> 404</span>
        <h1 class="mb-10">{{$t('page_not_found')}}</h1>
        <p class="mb-10">{{$t('page_not_found_text')}}</p>
        <button class="btn btn-primary btn-sm px-20 fw-bolder" @click="() => $router.go(-1)">{{$t('back')}}</button>
      </div>
    </div>
    <div class="text-center">
      <img src="/assets/media/illustrations/sketchy-1/illustration1.png" alt=""
                class="mw-100 h-225px h-sm-400px" />
    </div>
  </div>
</template>
<script setup>

</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.content {
  text-align: center;
}

.icon {
  font-size: 50px;
  margin-bottom: 20px;
}
</style>
