import * as types from './mutation-types'

export default {
  [types.LOAD_APPOINTMENTS](state, data) {
    state.appointments = data.appointments
  },

  [types.ADD_APPOINTMENT](state, data) {
    state.appointments.push(data)
  },

  [types.SET_CURRENT_APPOINTMENT](state, appointment) {
    state.currentAppointment = appointment.data
  },

  [types.LOAD_APPOINTMENT_STATUS](state, data) {
    state.appointmentStatus = data
  },

  [types.LOAD_APPOINTMENT_REMINDER_STATUS](state, data) {
    state.reminderStatus = data
  },

  [types.LOAD_TIME_ZONES](state, data) {
    state.timeZones = data.data
  },

  [types.LOAD_AVAILABLE_STATUS](state, data) {
    state.availableStatus = data
  },

  [types.LOAD_FREQUENCY_ENUM](state, data) {
    state.frequencyEnum = data
  },

  [types.LOAD_RECURRENCE_LIMIT_ENUM](state, data) {
    state.recurrenceLimitEnum = data
  },

  [types.LOAD_RECURRENCE_FREQUENCY_ENUM](state, data) {
    state.recurrenceFrequencyEnum = data
  }
}
