import * as types from './mutation-types'

export default {
  [types.UPDATE_APP_LOADING_STATUS]: (state, data) => {
    state.isAppLoaded = data
  },
  [types.SET_APP_ASIDE_MINIMIZE_STATE]: (state) => {
    state.appAsideMinimize = !state.appAsideMinimize
  }
}
