import { ref, computed } from 'vue'

export function useLocales() {

    const languages = ref({
        en : {
            'img': '/assets/media/flags/united-states.svg',
            'title': 'header.language.english',
            'code': 'en'
        },
        fr : {
            'img': '/assets/media/flags/france.svg',
            'title': 'header.language.french',
            'code': 'fr'
        },
    })

    const locale = computed(() => i18n.global.locale.value)
    const currentLanguage = computed(() => languages.value[locale.value])
    const datatableLocaleUrl = computed(() => {
        return locale.value === 'fr' ? "https://cdn.datatables.net/plug-ins/1.10.20/i18n/French.json" : "https://cdn.datatables.net/plug-ins/1.10.20/i18n/English.json"
    })

    function setLocale(code){
        i18n.global.locale.value = code
        Ls.set('locale', code)
    }

    return { locale, languages, currentLanguage, datatableLocaleUrl, setLocale }
  }
