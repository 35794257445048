export function useLoader() {
    function loading(id){
        $("#"+id).attr("data-kt-indicator","on")
        $("#"+id).attr("disabled",true)
    }

    function  stopLoading(id){
        $("#"+id).attr("data-kt-indicator",false)
        $("#"+id).attr("disabled",false)
    }


    return { loading,stopLoading }
}