import * as types from './mutation-types'
import {ADD_CONTACT_SUPPLIER, ADD_NEXT_CONTACTS, UPDATE_CONTACT} from "./mutation-types";

export default {
  [types.ADD_CONTACTS](state, data) {
    state.contacts = data.contacts
  },
  [types.ADD_CONTACT](state, data) {
    state.contacts.push(data)
  },
  [types.SET_AVATAR] (state, {id, avatar}) {
    state.contacts = state.contacts.map((contact) => { if (contact.id == id) {contact.avatar = avatar} return contact })
  },
  [types.UPDATE_CONTACT] (state, data) {
    state.contacts = state.contacts.map((contact) => { if (contact.id == data.id) {contact = data} return contact })
  },

  [types.ADD_SCROLL_CONTACTS_DATA] (state, data) {
    state.contactScroll = data.contacts
    state.contactScrollCount = data.count
  },

  [types.ADD_NEXT_CONTACTS] (state, data) {
    state.contactScroll = [...state.contactScroll, ...data.contacts]
  },

  [types.ADD_CONTACT_SUPPLIERS] (state, data) {
    state.contactSuppliers = data
  },

  [types.ADD_CONTACT_SUPPLIER] (state, data) {
    state.contactSuppliers.push(data)
  },
  [types.CONTACTS_STATS](state, data) {
    state.contactStats = data
  },
  

}
