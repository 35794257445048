
export default {
    /*
    |--------------------------------------------------------------------------
    | Crm Routes
    |--------------------------------------------------------------------------|
    */

    path: 'crm',
    name: 'crm',
    redirect: 'crm/components',
    meta: { requiresCrmAccess: true },
    children: [
        /*
        |--------------------------------------------------------------------------
        | CRM Components Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'components',
            component: () => import('@/Modules/CRM/views/ComponentsView.vue'),
            name: 'components',
        },

        /*
        |--------------------------------------------------------------------------
        | Pipeline Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'pipeline',
            children: [
                {
                    path: '',
                    component: () => import('@/Modules/CRM/views/Pipeline'),
                    name: 'pipelines',
                },
                {
                    path: ':id/view',
                    component: () => import('@/Modules/CRM/views/Pipeline/view'),
                    name: 'pipelines-view',
                    meta: {},
                },
                {
                    path: 'steps',
                    component: () => import('@/Modules/CRM/views/Pipeline/stepsList.vue'),
                    name: 'pipelines-steps',
                    meta: {},
                },

            ]
        },

          /*
        |--------------------------------------------------------------------------
        | Payment Routes
        |--------------------------------------------------------------------------|
        */

        {
            path: 'payment',
            children: [
                {
                    path: '',
                    component: () => import('@/Modules/CRM/views/Payment/Index.vue'),
                    name: 'payments',
                },
                {
                    path: 'create',
                    component:  () => import('@/Modules/CRM/views/Payment/Detail.vue'),
                    name: 'payments-create',
                    meta: { requiresPermission: '' },
                },
                {
                    path: 'edit/:id',
                    component:  () => import('@/Modules/CRM/views/Payment/Detail.vue'),
                    name: 'payments-edit',
                    meta: { requiresPermission: '' },
                },
                {
                    path: 'view/:id',
                    component:  () => import('@/Modules/CRM/views/Payment/view.vue'),
                    name: 'payments-view',
                    meta: { requiresPermission: '' },
                },
            ]
        },

        /*
        |--------------------------------------------------------------------------
        | Estimate Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'estimates',
            children: [
                {
                    path: '',
                    component:  () => import('@/Modules/CRM/views/Estimate/Index.vue'),
                    name: 'estimates',
                },
                {
                    path: 'create',
                    component:  () => import('@/Modules/CRM/views/Estimate/Detail.vue'),
                    name: 'estimates-create',
                    meta: { requiresPermission: 'estimate_view' },
                },
                {
                    path: 'edit/:id',
                    component:  () => import('@/Modules/CRM/views/Estimate/Detail.vue'),
                    name: 'estimates-edit',
                    meta: { requiresPermission: 'estimate_update' },
                },
                {
                    path: 'view/:id',
                    component: () => import('@/Modules/CRM/views/Estimate/view'),
                    name: 'estimates-view',
                    meta: {},
                }
            ]
        },

        /*
       |--------------------------------------------------------------------------
       | Settings Routes
       |--------------------------------------------------------------------------|
       */
        {
            path: 'crm-settings',
            name: 'crm-settings',
            component: () => import('@/Modules/CRM/views/Setting/indexView.vue'),
            meta: {  },
        },
        {
            path: 'crm-settings-pipelines',
            name: 'crm-settings-pipelines',
            component: () => import('@/Modules/CRM/views/Setting/Layouts/BaseSettingPipeline.vue'),
            children: [
                {
                    name: "crm-settings-pipelines-losse-reasons",
                    path: "losse-reasons",
                    component: () => import('@/Modules/CRM/views/Setting/LosseReasons/index.vue'),
                    meta: {
                        title: 'setting.companyInformation',
                        breadcrumbs: [
                            {
                                name: 'breadcrumbs.crm-setting.setting',
                                routeName: 'crm-setting-new'
                            }
                        ],
                        requiresPermission: '',
                    },
                },
                {
                    name: "crm-settings-pipelines-activity-types",
                    path: "activity-types",
                    component: () => import('@/Modules/CRM/views/Setting/ActivityTypes/index.vue'),
                    meta: {
                        title: 'setting.companyInformation',
                        breadcrumbs: [
                            {
                                name: 'breadcrumbs.crm-setting.setting',
                                routeName: 'crm-setting-new'
                            }
                        ],
                        requiresPermission: '',
                    },
                },
                {
                    name: "crm-settings-pipelines-steps",
                    path: "steps",
                    component: () => import('@/Modules/CRM/views/Setting/Steps/index.vue'),
                    meta: {
                        title: 'setting.companyInformation',
                        breadcrumbs: [
                            {
                                name: 'breadcrumbs.crm-setting.setting',
                                routeName: 'crm-setting-new'
                            }
                        ],
                        requiresPermission: '',
                    },
                },
            ]
        },
        {
            path: 'statistic',
            component: () => import('@/Modules/CRM/views/Statistic/index'),
            name: 'crm-statistics',
            meta: { requiresPermission: '' },
        },
          /*
        |--------------------------------------------------------------------------
        | Appointments Routes
        |--------------------------------------------------------------------------|
        */

        {
            path: 'appointments',
            children: [
                {
                    path: '',
                    component: () => import('@/Modules/CRM/views/Appointment/Index.vue'),
                    name: 'appointments',
                },
                {
                    path: 'create',
                    component:  () => import('@/Modules/CRM/views/Appointment/Detail.vue'),
                    name: 'appointments-create',
                    meta: { requiresPermission: '' },
                },
                {
                    path: 'edit/:id',
                    component:  () => import('@/Modules/CRM/views/Appointment/Detail.vue'),
                    name: 'appointments-edit',
                    meta: { requiresPermission: '' },
                },
                {
                    path: 'view/:id',
                    component:  () => import('@/Modules/CRM/views/Appointment/view.vue'),
                    name: 'appointments-view',
                    meta: { requiresPermission: '' },
                },
            ]
        },
    ]

}
