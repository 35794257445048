import * as types from './mutation-types'
import {BASE_URL} from "@/services/api";

export const loadCountries = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/countries/collection').then((response) => {
      commit(types.ADD_COUNTRIES, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const editCountry = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.patch('update/' + data.id + '/country', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}


export const createCountry = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('country', data).then((response) => {
      commit(types.ADD_COUNTRY, response.data.country)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}



export const deleteCountry = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.delete('/api/country/' + id + '/delete').then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

