import * as types from './mutation-types'

export default {
  [types.ADD_REASONS](state, data) {
    state.reasons = data.reasons
  },
  [types.ADD_REASON] (state, data) {
    state.reasons.push(data)
  },
}
