import * as types from './mutation-types'

export default {
  [types.LOAD_BANK](state, data) {
    state.banks = data.banks

   
  },
  [types.ADD_BANK](state, data) {
    state.banks = data.bank
   
    
  },

 
}
