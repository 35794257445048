import * as types from './mutation-types'
import {BASE_URL} from "../../../services/api";



export const loadDepartments = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/departments/collection').then((response) => {
      commit(types.LOAD_DEPARTMENTS, response.data.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const createDepartment = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.post('store/department', data).then((response) => {
      resolve(response)
      commit(types.ADD_DEPARTMENT, response.data.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateDepartment = ({ commit, dispatch, state }, { id , data}) => {
  return new Promise((resolve, reject) => {
    axios.put('update/department/'+ id, data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteDepartment = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    axios.delete("delete/department/" + id).then((response) => {
      commit(types.DELETE_DEPARTMENT, id)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const AddUsersToDepartment = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.post('set/users/to/department', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const getDepartment = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/department/'+ id).then((response) => {
      commit(types.LOAD_DEPARTMENT, response.data.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const getUsers = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/users/collection').then((response) => {
      commit(types.LOAD_USERS, response.data.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteUserOfDepartment = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.post("delete/user/from/department", data).then((response) => {
      //commit(types.DELETE_DEPARTMENT, id)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}


