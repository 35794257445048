import * as types from './mutation-types'
import {LOAD_PAYMENT_STATUS, LOAD_PAYMENT_TYPES} from "./mutation-types";

export default {
  [types.LOAD_PAYMENT_TYPES](state, data) {
    state.paymentTypes = data.data
  },
  [types.LOAD_PAYMENT_STATUS](state, data) {
    state.paymentStatus = data
  },
  [types.LOAD_PAYMENT_CONDITIONS](state, data) {
    state.paymentConditions = data.data
  },
}
