import * as types from './mutation-types'

export default {
  [types.LOAD_ESTIMATES](state, data) {
    state.estimates = data.estimates
  },

  [types.ADD_ESTIMATE](state, data) {
    state.estimates.push(data)
  },

  [types.SET_CURRENT_ESTIMATE](state, estimate) {
    state.currentEstimate = estimate.data
  },

  [types.LOAD_ESTIMATE_STATUS](state, data) {
    state.estimateStatus = data
  },
}
