import * as types from './mutation-types'

export default {
  [types.ADD_PAYMENT](state, data) {
    state.payments.push(data)
  },

  [types.SET_CRM_CURRENT_PAYMENT](state, payment) {
    state.currentPayment = payment.data
  },

  [types.LOAD_CRM_PAYMENT_STATUS](state, data) {
    state.paymentStatus = data
  },
}
