import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'


const initialState = {
  dashboardStats: {
    departmentsCount: 0,
    contacts: {
      contactsCount: 0,
      contactsClient: 0,
      contactsSupplier: 0
    },
    users: {
      activeUsersCount: 0,
      disableUsersCount: 0
    },
    articles: {
      articlesTypeServiceCount: 0,
      articlesTypeProductCount: 0,
      articleCount: 0
    },
    invoiceTurnover: {
      invoiceTurnover: 0,
      invoicesCount: 0,
      invoicesPaidCount: 0,
      invoicesNotPaidCount: 0
    }

  },
  statistics: {},
  saleStatistics: {}
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
