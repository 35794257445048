<template>
    <div class="modal fade" :id="id" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <form @submit.prevent="createPosts" class="form">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('post.creation')  }}</h5>
                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" @click="closeModal" aria-label="Close">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                                        fill="currentColor"/>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                        fill="currentColor"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body">
                        <RequiredText />
                        <div class="d-flex flex-column mb-8 fv-row">
                            <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                                <span class="required">{{$t('post.title')}}</span>
                            </label>
                            <input type="text" class="form-control form-control-solid" v-model="formData.title"  :placeholder="$t('post.title')" required />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button btn-class="btn-sm me-2" type="cancel" btn-text="button.close" size="7px 30px" @click="closeModal"/>
                        <Button btn-class="btn-sm" type="submit" btn-text="button.save" size="7px 30px" :isLoading="isLoading" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>

    import {ref, defineProps,defineEmits} from 'vue'
    import {useStore} from "vuex";
    import RequiredText from "@/components/RequiredText"
    import Button from "@/components/Button.vue"

    const store = useStore()
    const isLoading = ref(false)
    const props = defineProps({
        parent:{
            type:String,
            required:false
        },
        id: {
            type: String,
            required: true
        }
    })
    const processing = ref(false)
    const formData = ref({
         title: '',
    })
   const emits = defineEmits(['postOffice'])

   
    function closeModal(){
  if(props.parent){
      $('#'+props.parent).modal('toggle')
  }
  $('#postCreateModal').modal('toggle')
}
    function createPosts() {
        isLoading.value = true
        store.dispatch('post/createPost', formData.value)
            .then((response) => {
                
                const postId = response.data.data.id;

                if(postId){
                    emits("postOffice",postId)
                }
                closeModal()
                formData.value.title = ''
                window.toastr['success'](i18n.global.t('post.created'))
                isLoading.value = false
            })
            .catch(err => {
                isLoading.value = false
            })
    }


   

    
</script>