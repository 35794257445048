import i18n from "@/plugins/i18n"

const pipelineGroup = ['pipelines', "pipelines-new", "pipelines-edit", "pipelines-view","pipelines-steps", "pipelines-steps"]
const settingGroup = ['crm-settings', "crm-settings-pipelines","crm-settings-pipelines-losse-reasons", "crm-settings-pipelines-activity-types", "crm-settings-pipelines-steps"]
const paymentGroup = ['payments', "payments-edit", "payments-create", "payments-view"]
const estimatesGroup = ["estimates", "estimates-view", "estimates-edit", "estimates-create"]
const statisticGroup = ["crm-statistics"]
const appointmentGroup = ['appointments', "appointments-edit", "appointments-create", "appointments-view"]

export default {
    menus:  {
        sectionTitle: "CRM",
        children: [
            {
                title: i18n.global.t('menu.crm.pipeline'),
                icon: 'fas fa-handshake icon-right2',
                route: 'pipelines',
                group: pipelineGroup,
                id: 'step-pipelines',
                permission: [],
                children: [],
                query: {viewType: 'list'}
            },
            {
                title: i18n.global.t('menu.crm.devis'),
                icon: 'fas fa-file-alt icon-right2',
                route: 'estimates',
                group: estimatesGroup,
                id: 'step-estimates',
                permission: [],
                children: [],
                query: {}
            },
            {
                title: i18n.global.t('menu.crm.payment'),
                icon: 'fas fa-file-invoice-dollar icon-right1',
                route: 'payments',
                group: paymentGroup,
                id: 'step-payments',
                permission: [],
                children: [],
                query: {}
            },
            {
                title: i18n.global.t('menu.crm.appointments'),
                icon: 'fas fa-calendar-alt icon-right1',
                route: 'appointments',
                group: appointmentGroup,
                id: 'step-appointment',
                permission: [],
                children: [],
                query: {}
            },
            {
                title: i18n.global.t('menu.statistics'),
                icon: 'fas fa-chart-line icon-right2',
                route: 'crm-statistics',
                group: statisticGroup,
                id: 'step-crm-statistic',
                permission: [],
                children: []
            },
            {
                title: i18n.global.t('menu.settings'),
                icon: 'fas fa-cog icon-right2',
                route: 'crm-settings',
                group: settingGroup,
                id: 'step-settings',
                permission: [],
                children: [],
                query: {}
            },

        ]
    }
}
