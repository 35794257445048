export const Theme = {
    blue: "#0072CE",
    orange: "#F07817",
    yellow: "#F3DC1F",
    green: "#2DA333",
    red: "#E51335",
    gray: "#303D4E",
    "gray-100": "#F2F3F4",
    "gray-200": "#E6E7E9",
    "gray-300": "#CCCFD3",
    "gray-400": "#989EA7",
    "gray-500": "#646E7B",
    white: "#FFFFFF",
}
