import * as types from './mutation-types'
import { ADD_VARIATION } from "@/store/modules/variations/mutation-types";

export const createAttribut = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/attribut', data).then((response) => {
            let variations = response.data.attribut.variations || []
            variations.forEach((variation) => {
                commit(`variations/${ADD_VARIATION}`, variation, {root :true})
            })
            commit(types.ADD_ATTRIBUT, response.data.attribut)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateAttribut = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/attribut', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteAttribut = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+id+ '/attribut').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAttributs = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/attributs/collection`).then((response) => {
            commit(types.ADD_ATTRIBUTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createValueAttribut = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/' + data.attribut + '/attribut/value', data).then((response) => {
            let variations = response.data.variations || []
            variations.forEach((variation) => {
                commit(`variations/${ADD_VARIATION}`, variation, {root :true})
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



