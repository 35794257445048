import * as types from './mutation-types'

export default {
  [types.ADD_APPOINTMENT_TYPES](state, data) {
    state.types = data.data
  },
  [types.ADD_APPOINTMENT_TYPE] (state, data) {
    state.types.push(data)
  },
}
