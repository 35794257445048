import * as types from './mutation-types'

export default {
  [types.LOAD_POST](state, data) {
    state.post = data.postOffices
  },
  [types.ADD_POST](state, data) {
    state.post.push(data.data)
  },

 
}
