import * as types from './mutation-types'

export default {
  [types.ADD_SOURCES](state, data) {
    state.sources = data.opportunitySources
  },
  [types.ADD_SOURCE] (state, data) {
    state.sources.push(data)
  },
}
