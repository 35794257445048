export function useInitialName() {

    function initial(fullname){
        let name = ''
        const [firstname, lastname] = fullname.toUpperCase().split(' ')
        name += firstname[0]
        if(lastname) {
          name += lastname[0]
        }
        return name
    }

    return { initial }
}
