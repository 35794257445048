<template>
  <div class="d-flex flex-center flex-column flex-column-fluid">
    <div class="w-lg-500px p-10 p-lg-15 mx-auto">
      <form class="form w-100" @submit.prevent="sendForgotPasswordEmail" id="kt_password_reset_form">
        <div class="text-center mb-10">
          <h1 class="text-dark mb-3">{{ $t('auth.forgot_password') }}</h1>
          <div class="text-gray-400 fw-bold fs-4">{{ $t('auth.enter_mail') }}</div>
        </div>
        <div class="fv-row mb-10">
          <label class="form-label fw-bolder  fs-6 text-color required">{{ $t('auth.email') }}</label>
          <input v-model="form.email" class="form-control form-control-solid" type="email"  autocomplete="off" />
        </div>
        <div class="d-flex flex-wrap justify-content-center ">
          <button type="submit" id="kt_submit_email_forgotten" class="btn btn-lg btn-primary fw-bolder me-4">
            <span class="indicator-label">{{ $t('button.send') }}</span>
            <span class="indicator-progress">{{  $t('loading') }}
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <router-link :to="'/login'" class="btn btn-lg btn-light fw-bolder">{{ $t('button.cancel') }}</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'ForgotPasswordView',
  data(){
    return {
      form : {
        email : ''
      },
      langs: {
        en : {
          'img': '/assets/media/united-states.svg',
          'title': 'header.language.english',
          'code': 'en'
        },
        fr : {
          'img': '/assets/media/france.svg',
          'title': 'header.language.french',
          'code': 'fr'
        },
      }
    }
  },
  computed : {
    currentLanguage(){
      return this.langs[this.$i18n.locale]
    },
  },
  mounted(){
  },

  methods: {
    ...mapActions('auth', [
      'forgetPassword'
    ]),
    changeLang(lang){
      this.$i18n.locale = lang.code
      localStorage.setItem('locale', lang.code)
    },
    async sendForgotPasswordEmail(){
      this.$loading('kt_submit_email_forgotten')
      let response = {}

      try{
        response = await this.forgetPassword(this.form)
        this.form.email = ''
        Swal.fire({
          text: i18n.global.t('auth.reset_password_email_sent'),
          icon: "success",
          showCancelButton: false,
          buttonsStyling: false,
          customClass: { confirmButton: "btn ", cancelButton: "btn texte-white" },
          confirmButtonColor: '#0072CE',
          cancelButtonColor: '#d33',
        })

        this.$stopLoading('kt_submit_email_forgotten')
        return true

      }catch(err){
        this.$stopLoading('kt_submit_email_forgotten')
        return true
      }
    },
  },

  updated() {
    this.$nextTick(() => {
      KTApp.init();
    })
  },
}
</script>
<style scoped>
.text-color{
  color: rgba(16, 16, 16, 0.50);
}
</style>
