import * as types from './mutation-types'

export const loadVariations = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/variations/collection`).then((response) => {
            commit(types.ADD_VARIATIONS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const updateVariation = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.variation + '/variation', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteVariation = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+ data.variation +'/variation', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

