import * as types from './mutation-types'


export const createProduct = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/product', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createProductVariations = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/product/variations', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const findProductById = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.get(`product/${id}/details`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const importProducts = ({ commit }, data) => {
    let formData = new FormData();
    formData.append('file', data.file);
    formData.append('_method', 'POST');
    return new Promise((resolve, reject) => {
        window.axios.post(`import/products`, formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const exportProduct = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('export/products', { responseType: 'blob' }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'products.xlsx');
            document.body.appendChild(link);
            link.click();
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadProductModel = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('download-product-model', { responseType: 'blob' }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'model-product.xlsx');
            document.body.appendChild(link);
            link.click();
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteProduct = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete(`product/${id}/delete`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteProductSaleElement = ({ commit }, {productSaleElement, productId}) => {

    return new Promise((resolve, reject) => {
        window.axios.delete(`/productSaleElement/${productSaleElement}/product/${productId}/delete`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateProductSaleElements = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/productSaleElements/update`, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteProductSaleElements = ({ commit }, {data, productId}) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/productSaleElements/product/${productId}/delete`, {params: {productSaleElements: data}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const loadAllProductSale = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/all/productSaleElements/collection').then((response) => {
            commit(types.LOAD_PRODUCT_SALE_ELEMENTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const removeProductStore = ({commit}, data) => {
    return new Promise((resolve, eject) => {
        window.axios.delete(`store/${data.id}/product/${data.product_sale_element_id}/delete`).then((response) => {
            resolve(response)
        }).catch((err) => {
            eject(err)
        })
    })
}



export const uploadImage = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('product/upload-avatar', data).then((response) => {
            commit(types.SET_IMAGE, { id: response.data.id, image: response.data.image })

            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const loadTypes = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/product/type/enum').then((response) => {
            commit(types.ADD_TYPES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}