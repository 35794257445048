import * as types from './mutation-types'
import {BASE_URL} from "@/services/api";



export const loadRole = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/roles/collection').then((response) => {
      commit(types.LOAD_PROFILES, response.data.roles)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
export const createRole = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.post('store/role', data).then((response) => {
      resolve(response)
      commit(types.ADD_ROLE, response.data.role)
    }).catch((err) => {
      reject(err)
    })
  })
}
export const deleteRole = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    axios.delete('delete/' + id + '/role').then((response) => {
      // commit(types.UPDATE_CURRENT_USER, response.data.user)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateRole = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.patch('update/' + data.id + '/role', data).then((response) => {
      // commit(types.UPDATE_CURRENT_USER, response.data.user)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const getPermissionForProfile = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.get('role/' + data + '/getPermissionToRole').then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
export const togglePermissionToProfile = ({ commit, dispatch, state }, {id, data}) => {
  return new Promise((resolve, reject) => {
    window.axios.post('role/' + id + '/attributePermissionToRole', data).then((response) => {
      if (response.data) {
        // commit(types.DELETE_PROFILE_TYPE, id)
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
export const fetchProfile = ({ commit, dispatch, state },id) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/' +id+ '/role').then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
export const togglePermissionToUser = ({ commit, dispatch, state }, {id, data}) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`role/${id}/attributePermissionToUser`, data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
