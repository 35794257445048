export const ADD_CONTACTS = 'ADD_CONTACTS'
export const ADD_CONTACT = 'ADD_CONTACT'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const SET_AVATAR = 'SET_AVATAR'
export const ADD_SCROLL_CONTACTS_DATA = 'ADD_SCROLL_CONTACTS_DATA'
export const ADD_NEXT_CONTACTS = 'ADD_NEXT_CONTACTS'
export const ADD_CONTACT_SUPPLIERS = 'ADD_CONTACT_SUPPLIERS'
export const ADD_CONTACT_SUPPLIER = 'ADD_CONTACT_SUPPLIER'
export const CONTACTS_STATS = 'CONTACTS_STATS'


