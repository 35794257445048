<template>
  <div class="content d-flex flex-column flex-column-fluid p-0" id="kt_content2">
    <div class="toolbar" id="kt_toolbar">
      <SubHeader
          :title="pageTitle"
          :breadcrumbs="pageBreadcrumbs"
          :back="hasBack"
      >
        <slot name="subHeaderActions" />
        <div v-show="$slots.subHeaderBottomActions" class="d-block d-lg-none container-fluid d-flex flex-stack">
          <slot name="subHeaderBottomActions" />
        </div>
      </SubHeader>
    </div>

    <div v-show="$slots.subHeaderBottomActions" class="toolbar actionBar h-70px rounded-3 px-5">
      <slot name="subHeaderBottomActions" />
    </div>

    <div v-show="!loading">
      <div v-show="$slots.layoutContent" id="kt_content_container" class="container-xxl py-5" style="margin-bottom: 30px">
        <slot name="layoutContent" />
      </div>
      <slot name="customLayoutContent" />
    </div>

  </div>
</template>

<script setup>
import {defineProps} from "vue";
import SubHeader from "@/views/Layouts/partials/SubHeader.vue";
import {useStore} from "vuex";

const store = useStore()

defineProps({
  loading: { type: Boolean, default: false },
  pageTitle: String,
  pageBreadcrumbs: {type: Array, default: () => []},
  hasBack: { type: Boolean, default: true },
})


</script>

<style scoped>

.container-xxl {
  max-width: 1600px;
}

.actionBar {
//position: absolute;
  top: 130px!important;
//left: 300px;
//right: 30px;
//z-index: 90;
}


@media (min-width:1111px) {
  .toolbar-fixed .actionBar {
    height: var(--kt-toolbar-height);
    position: absolute;
    top: 0;
    right: 30px;
    left: 30px;
    z-index: 90;
    box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .05)
  }
  .toolbar-enabled:not(.toolbar-fixed) .actionBar {
    margin-bottom: 30px
  }
  .header-fixed.toolbar-fixed .actionBar {
    padding: 0;
    top: calc(65px + var(--kt-toolbar-height));
    border-top: 1px solid #eff2f5
  }
  .aside-enabled.aside-fixed.toolbar-fixed .actionBar {
    left: 300px;
    transition: left .3s ease
  }
  .aside-enabled.aside-fixed.toolbar-fixed[data-kt-aside-minimize=on] .actionBar {
    left: 110px;
    transition: left .3s ease
  }
}

@media (max-width:1110px) {
  .toolbar-tablet-and-mobile-fixed .actionBar {
    height: var(--kt-toolbar-height-tablet-and-mobile);
  //position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 90;
    box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .05)
  }
  .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) .actionBar {
    margin-bottom: 15px
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .actionBar {
    padding: 0;
    top: 55px;
    border-top: 1px solid #eff2f5
  }
}


</style>
