import * as types from './mutation-types'
import {BASE_URL} from "@/services/api";

export const loadGenders = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/gender/enum').then((response) => {
      commit(types.ADD_GENDERS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

