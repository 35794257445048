import * as types from './mutation-types'

export default {
  bootstrap ({ commit}) {
    return new Promise((resolve, reject) => {
      window.axios.get('/api/bootstrap').then((response) => {
        commit(types.UPDATE_APP_LOADING_STATUS, true)
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  toggleAsideState({commit}) {
    return commit(types.SET_APP_ASIDE_MINIMIZE_STATE)
  }
}
