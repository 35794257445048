import * as types from './mutation-types'

export const loadBrands = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/brands/collection', data).then((response) => {
            commit(types.ADD_BRANDS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateBrand = ({ commit }, data) => {

    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/brand', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createBrand = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/brand', data).then((response) => {
            commit(types.ADD_BRAND, response.data.brand)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const deleteBrand = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/brand').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

