import * as types from './mutation-types'

export const loadReasons = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/reasons/collection', data).then((response) => {
            commit(types.ADD_REASONS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateReason = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/reason', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createReason = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/reason', data).then((response) => {
            commit(types.ADD_REASON, response.data.reason)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteReason = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/reason').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

