import * as types from './mutation-types'

export default {

  [types.LOAD_DEPARTMENTS] (state, departments) {
    state.departments = departments
  },
  
  [types.LOAD_DEPARTMENT] (state, department) {
    state.departments = department
  },

  [types.LOAD_USERS] (state, users) {
    state.users = users
  },

  [types.ADD_DEPARTMENT](state, data) {
    state.departments.push(data)
  },

  [types.DELETE_DEPARTMENT](state, id) {
    state.departments = state.departments.filter(department => department.id!== id)
  },

}

