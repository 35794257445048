<template>
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div
        data-kt-swapper="true"
        data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">

      <h1 class="d-flex text-dark fw-bolder fs-3 align-items-center my-1 ">{{ $t(title) }}</h1>
      <span class="h-20px border-gray-300 border-start mx-4"></span>
      <div class="d-flex justify-content-between">
        <ul
            class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1 "
            v-for="(bread,key) in breadcrumbs"
            :key="key">
          <li v-if="key+1 !== breadcrumbs.length" class="breadcrumb-item text-muted">
            <router-link  :to="{name: bread.route, params: bread?.params}" class="text-muted text-hover-primary">{{  $t(bread.name) }}</router-link>
            <span class="bullet bg-gray-200 w-5px h-2px m-2"></span>
          </li>
          <li v-else class="breadcrumb-item text-primary">{{ $t(bread.name) }}</li>
        </ul>
      </div>
    </div>
    <div class="d-flex align-items-center gap-2 gap-lg-3">
      <div class="m-0">
        <slot  />
        <BackButton v-if="back"/>
      </div>
    </div>
  </div>
</template>

<script setup>

import { defineEmits, defineProps, onMounted} from "vue";
import BackButton from "@/components/Navigation/BackButton.vue";
import { useLocales } from "@/composables/locales";

const {locale} = useLocales()

defineProps({
  title: { required : true , type: String, default: 'Titre' },
  breadcrumbs: { type: Array, default: () => [] },
  back: { required : false , type: Boolean, default: true },
})

const emit = defineEmits(['updateSelect2'])

onMounted(() => {
  emit('updateSelect2', true)
})

</script>

<style scoped>

</style>
