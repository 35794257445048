import * as types from './mutation-types'

export const loadStatistics = ({ commit }, query) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`get/crm/statistics?startDate=${query.start_date}&endDate=${query.end_date}`).then((response) => {
      commit(types.LOAD_STATISTICS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}