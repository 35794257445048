export const ADD_PRODUCTS = 'ADD_PRODUCTS'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const ADD_STORE_PRODUCT_SALE_ELEMENTS = 'ADD_STORE_PRODUCT_SALE_ELEMENTS'
export const SET_IMAGE = 'SET_IMAGE'
export const SET_MULTI_IMAGE = 'SET_MULTI_IMAGE'
export const LOAD_PRODUCT_SALE_ELEMENTS = 'LOAD_PRODUCT_SALE_ELEMENTS'
export const LOAD_PRODUCT_SALE_ELEMENTS_BY_CATEGORIES = 'LOAD_PRODUCT_SALE_ELEMENTS_BY_CATEGORIES'
export const SET_PRODUCT_LOTS = 'SET_PRODUCT_LOTS'
export const ADD_TYPES = 'ADD_TYPES'

