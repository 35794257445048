import * as types from './mutation-types'


export const loadPosts = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/postoffices/collection').then((response) => {
            commit(types.LOAD_POST, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createPost = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/postoffice', data).then((response) => {
            commit(types.ADD_POST, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}




