import * as types from './mutation-types'

export const loadTaxes = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/taxes/collection').then((response) => {
            commit(types.ADD_TAXES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const createTaxe = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/taxe', data).then((response) => {
            commit(types.ADD_TAXE, response.data.taxe)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateTaxe = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/taxe', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteTaxe = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/taxe').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

