import * as types from './mutation-types'




export const loadMailMessage = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/emailMessage/collection', data).then((response) => {
            commit(types.ADD_MAIL, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateMail = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        
        window.axios.patch('update/'+data.id+'/emailMessage', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}