import * as types from './mutation-types'

export const loadCurrencies = ({commit, dispatch, state}) => {
    return new Promise((resolve, eject) => {
        window.axios.get('get/currencies/collection').then((response) => {
            commit(types.LOAD_CURRENCIES, response.data.currencies)
            resolve(response)
        }).catch((err) => {
            eject(err)
        })
    })
}

export const setDefaultCurrency = ({commit, dispatch, state}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('set/default/currency/' + data.currency_id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setOnlineCurrency = ({commit, dispatch, state}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/currency/' + data.currency_id + '/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateCurrency = ({commit, dispatch, state}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/currency/' + data.id , data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}