import * as types from './mutation-types'

export default {
  [types.ADD_COUNTRIES] (state, data) {
    state.countries = data.countries
  },

  [types.ADD_COUNTRY] (state, data) {
    state.countries.push(data)
  },

}
