import * as types from './mutation-types'

export const loadActivityTypes = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/typeActivities/collection', data).then((response) => {
            commit(types.ADD_ACTIVITY_TYPES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadActivityIcons = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/activity/icon', data).then((response) => {
            commit(types.ADD_ACTIVITY_ICONS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateActivityType = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/typeActivity', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateActivityTypeStatus = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/typeActivity/' + data.id + '/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createActivityType = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/typeActivity', data).then((response) => {
            commit(types.ADD_ACTIVITY_TYPE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const deleteActivityType = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/typeActivity').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

