<template>
  <button :disabled="isDisabled || isLoading"
          :style="buttonStyle"
          :class="[ btnClass || '',defaultClass]"
          :type="type"
          :data-kt-indicator="isLoading ? 'on' : ''"
          :title="title"
          data-bs-toggle="tooltip" 
          data-bs-placement="top"
  >
    <span v-if="btnText" class="indicator-progress">
      <span v-if="spinnerDir === 'left'" class="spinner-border spinner-border-sm align-middle me-2"></span>
      {{ $t("loadingText") }}
      <span v-if="spinnerDir === 'right'" class="spinner-border spinner-border-sm align-middle ms-2"></span>
    </span>

    <i v-if="!isLoading" :class="[iconClass,iconSize]" :style="{color: iconColor}"></i>
    <span v-if="btnText" class="fw-bold indicator-label">{{  $t(btnText)  }}</span>
  </button>
</template>

<script>
export default {
  name: "OutlineButton",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: ""
    },
    btnClass: String,
    iconClass: String,
    color: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'auto'
    },
    iconSize: {
      type: String,
      default: 'fs-3'
    },
    spinnerDir: {
      type: String,
      default: "right"
    },
    border: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    title: String,
    height: {
      type: String,
      default: "45px"
    },
  },

  computed: {
    buttonStyle() {
      const baseStyle = {
        borderRadius: '8px',
        borderWidth: this.border ? '1px' : '0',
        borderStyle: this.border ? 'solid' : 'none',
        borderColor: this.border ? this.color : '#fff',
        padding: this.size
      };

      switch (this.type) {
        case 'danger':
          return { ...baseStyle, color: 'red', backgroundColor: '#fff' };
        case 'primary':
          return { ...baseStyle, color: '#0072CE', backgroundColor: '#fff' };
        case 'warning':
          return { ...baseStyle, color: '#F07817', backgroundColor: '#fff' };
        case 'success':
          return { ...baseStyle, color: '#2DA333', backgroundColor: '#fff' };
        default:
          return { ...baseStyle, color: this.color, backgroundColor: '#fff' };
      }
    },
    defaultClass(){
      if(!this.btnText && this.iconClass){
        return "btn btn-icon btn_class shadow"
      }else{
        return "btn btn_class shadow"
      }
    },
    iconColor(){
      switch (this.type) {
        case 'danger':
          return 'red'
        case 'primary':
          return '#0072CE'
        case 'warning':
          return '#F07817'
        case 'success':
          return '#2DA333'
        default:
          return this.color
      }
    }
  }
}
</script>

<style scoped>
.btn_class {
  border-radius: 8px;
}
</style>
