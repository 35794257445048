<template>
    <div :class="[containerClass, hasIcon(icon)]">
      <label :id="`date-input-${name}-label`" :for="`date-input-${name}`" :class="[labelClass || 'form-label fs-6 fw-bolder text-black', {isRequired: 'required'}]">
        {{ label }} <span v-if="isRequired" class="text-danger"> * </span>
      </label>
      <div  :class="IsInputGroup ? 'input-group' : ''">
          <span v-show="iconDir === 'left'" :id="`date-input-${name}-addon`" class="input-group-text" :class="addonClass">
          {{ iconText }}
            <i :class="getIcon(icon)"></i>
          </span>
          <div class="input-with-icon">
            <div class="icon-wrapper">
              <i class="fas fa-calendar" style="color: rgba(100, 110, 123, 1)"></i>
              <span class="icon-text">31</span>
            </div>
            <input
              class="form-select form-control"
              style="color: rgba(48, 61, 78, 1)"
              :class="[{ 'invalid': (meta.touched && !meta.valid) }, getClasses(size), inputClass]"
              :name="name"
              :id="`date-input-${name}`"
              v-model="value"
              :placeholder="placeholder"
              :required="isRequired"
              :aria-label="label"
              :aria-describedby="`input-${name}-addon`"
              :disabled="isDisabled"
              :rules="rules"
            />
          </div>
          <span v-show="iconDir === 'right'" :id="`date-input-${name}-addon`" class="input-group-text" :class="addonClass">
            {{ iconText }}
              <i :class="getIcon(icon)"></i>
          </span>
      </div>
    </div>
  </template>

  <script setup>


  import { useField } from 'vee-validate';
  import { nextTick, onMounted, watch} from "vue";
  const props = defineProps( {
    size: {
      type: String,
      default: "lg",
    },
    parentId: { type: String, default: '' },
    icon: String,
    iconDir: String,
    name: String,
    id: String,
    modelValue: String,
    placeholder: String,
    isRequired: {type: Boolean, default: true},
    inputClass: {type:String, default: "form-control form-control-lg form-control-solid"},
    labelClass: {type:String, default: "form-label"},
    containerClass: {
      type: String,
      default: 'col-12 mt-2 mb-5'
    },
    label: String,
    isDisabled: Boolean,
    rules: String,
    iconText: String,
    IsInputGroup: Boolean,
    addonClass: String,
    disabled: Array,
    dateFormat: String,
    enableTime: {type: Boolean, default: false},
    noCalendar: {type: Boolean, default: false},
    minDate: String,
    maxDate: String
  })

  const { handleChange, value, handleBlur, errorMessage, meta } = useField(() => props.name, undefined, {
    syncVModel: true,
  });

  watch(() => props.modelValue, (value) => {
    if(value) {
      nextTick(() => {
        initDataPicker()
      })
    }
  })

  watch(() => [props.minDate, props.maxDate], (value) => {
    if(value) {
      nextTick(() => {
        initDataPicker()
      })
    }
  })

  function getClasses (size) {
    let sizeValue, isValidValue;

    sizeValue = size ? `form-control-${size}` : null;

    isValidValue = meta.touched && !meta.valid ? `invalid` : "valid";

    return `${sizeValue} ${isValidValue}`;
  }
  function getIcon (icon) {
    return icon ? icon : null
  }
  function hasIcon (icon) {
    return icon ? "input-group" : null
  }

  function initDataPicker() {
    $("#date-input-" + props.name).flatpickr({
      onReady: function () {
        const initDate = props.modelValue ? new Date(props.modelValue) : new Date()
        this.jumpToDate(initDate)
      },
      dateFormat: props.dateFormat || "d-m-Y",
      altFormat: 'Y-m-d',
      enableTime:  props.enableTime,
      noCalendar:  props.noCalendar,
      disable: props.disabled || [],
      minDate: props.minDate || '',
      maxDate: props.maxDate || '',
    })
  }

  onMounted(() => {
    initDataPicker()
  })

  </script>
  <style scoped>
  input.invalid {
    border: 1px solid red;
  }

  .input-with-icon {
    position: relative;
  }

  .icon-wrapper {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-wrapper i {
    position: relative;
    font-size: 1.5em;
  }

  .icon-wrapper .icon-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    color: white;
    margin-top: 8px;
  }

  .input-with-icon input {
    padding-left: 40px;
  }
  </style>
