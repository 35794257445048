import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Ls from './services/ls'
import axios from 'axios'
import { BASE_URL } from './services/api'
import i18n from './plugins/i18n'
import axiosDefault from "@/services/defaultAxios";
import Permissions from './mixins/Permissions'
import { useLocales } from '@/composables/locales';
import {useLoader} from "@/composables/loader";
import mitt from 'mitt'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


window.axios = axios

window.axiosDefault = axiosDefault

window.Ls = Ls
window.i18n = i18n
window.api_url = BASE_URL

const emitter = mitt()

window.axios.defaults.baseURL = BASE_URL

axios.interceptors.request.use(function (config) {
    const AUTH_TOKEN = Ls.get('auth.token')
    const { currentLanguage } = useLocales();

    config.headers['X-localization'] = currentLanguage.value?.code;
    if (AUTH_TOKEN) {
        config.headers.Authorization = `Bearer ${AUTH_TOKEN}`
    }
    return config
}, function (error) {
    return Promise.reject(error)
})


global.axios.interceptors.response.use(undefined, function (err) {
    if (!err.response) {
        window.toastr.error('Network error: Please check your internet connection or wait until servers are back online')

    } else {
        if (err.response.data && (err.response.statusText === 'Unauthorized' || err.response.status === 401 || err.response.data === ' Unauthorized.')) {
            window.toastr.error((err.response.data.message) ? err.response.data.message : 'Unauthorized')
            store.dispatch('auth/logout', true)
            router.push('/login')
        } else if (err.response.data && err.response.status === 404 && Ls.get('auth.token')) {

        } else if (err.response.data && err.response.status === 403) {

        } else if (err.response.data && err.response.status === 422) {
            if(typeof err.response.data.message == "object"){
                window.toastr.error(Object.values(err.response.data.message).join(", "))
            }else{
                window.toastr.error((err.response.data.message) ? err.response.data.message : i18n.global.t('main.error_try'))
            }
        } else if (err.response.data.errors) {
            // Show a notification per error
            const errors = JSON.parse(JSON.stringify(err.response.data.errors))
            for (const i in errors) {
                window.toastr.error(errors[i])
            }
        } else if (err.response.data && (err.response.data == "Your account has been blocked")) {
            window.toastr.error("Your account has been blocked")
            store.dispatch('auth/logout', true)
        } else {
            // Unknown error
            window.toastr.error((err.response.data.message) ? err.response.data.message : 'Unknown error occurred')
        }
    }

    return Promise.reject(err)
})


window.vueJs = createApp(App)
    .use(store)
    .use(i18n)
    .use(router)
    .mixin(Permissions)
    .use(VueAxios, axios)
    .provide('emitter', emitter)
    .mixin({
        methods: {
            $loading: useLoader().loading,
            $stopLoading: useLoader().stopLoading
        }
    })
    .mount('#app')
