<template>
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-column flex-lg-row flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap mb-4 mb-lg-0">
            <h1 class="text-dark fw-bolder fs-3 align-items-center my-1">{{ $t(title) }}</h1>
            <span class="h-20px border-gray-300 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1 d-flex flex-wrap">
                <li v-for="(bread, key) in breadcrumbs" :key="key" class="breadcrumb-item" :class="{'text-muted': key + 1 !== breadcrumbs.length, 'text-primary': key + 1 === breadcrumbs.length}">
                    <router-link v-if="key + 1 !== breadcrumbs.length" :to="bread.path" class="text-muted text-hover-primary">{{ $t(bread.name) }}</router-link>
                    <span v-if="key + 1 !== breadcrumbs.length" class="bullet bg-gray-200 w-5px h-2px m-2"></span>
                    <span v-else>{{ $t(bread.name) }}</span>
                </li>
            </ul>
        </div>
        <div class="d-flex align-items-center gap-2 gap-lg-3 flex-column flex-lg-row">
            <div class="m-0 flex-grow-1">
                <div class="input-group input-group-solid flex-nowrap">
                    <span class="input-group-text h-35px"><i class="fs-3 fas fa-users"></i></span>
                    <div class="overflow-hidden">
                        <select name="contact" v-model="$route.params.id" data-control="select2" data-search="true" data-placeholder="Filter" class="form-select form-select-solid kt_select2 w-150px w-lg-250px h-35px" style="height: 34px;">
                            <option v-for="contact in getContacts" :value="contact.id" :key="contact.id">{{ contact.lastname }} {{ contact.firstname }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="m-0">
                <Button v-if="back" icon-class="fas fa-backspace" btn-class="btn-sm" type="cancel" btn-text="back" @click="$goTo('/admin/contacts')"/>
            </div>
        </div>
    </div>

</template>


<script setup>

import {onMounted} from "vue";
import {useRouter} from "vue-router";
import Button from "@/components/Button.vue"

const router = useRouter()
const emits = defineEmits(['initSelect'])
const props = defineProps({
  title: { required : true , type: String, default: 'Titre' },
  breadcrumbs: { required : false , type: Array, default: [] },
  back: { required : false , type: Boolean, default: false },
  getContacts: {type: Array, required: false ,default: [] },
})

onMounted(() => {
  emits('initSelect')
})

function $goTo(url){
  router.push(url)
}
</script>
