<template>
  <div class="row my-5">
    <div class="col-12 col-md-6 col-lg-3 mb-4" v-for="(status, index) in contactStats" :key="index">
      <div class="card card-body pt-6 h-100">
        <div class="d-flex flex-stack">
          <div class="symbol symbol-40px me-4">
            <div class="symbol-label fs-2" :class="status.name == 'CANCELLED' ? 'bg-secondary' : 'bg-'+filterStatus(status.name)[0]?.className">
              <i class="fas fa-file-alt fs-2" style="color: white;"></i>
            </div>
          </div>
          <div class="d-flex align-items-center flex-row-fluid flex-wrap">
            <div class="flex-grow-1 me-2">
              <a class="text-gray-800 fs-6 fw-bold">
                {{$t('crm.pipeline.title') + ' ' + filterStatus(status.name)[0]?.[locale]}}
              </a>
              <span class="text-muted fw-bold d-block fs-7">{{status?.count}}</span>
            </div>
            <div>{{formattedLocalAmount(status?.amount)}} {{currency}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Card :title="$t('pipelines.list')" containerClass="mb-5">
    <template #cardToolbar>
      <ButtonDropdownFilter
          :btn-title="$t('button.all_status')"
          :search="false"
          :options="filterOpportunityStatus"
          value-field="value"
          :name-field="locale"
          v-model="filter.status"
      />
      <ButtonDropdownFilter
          :btn-title="$t('label.allSellers')"
          :options="[...salesPersons]"
          value-field="id"
          name-field="fullname"
          v-model="filter.seller"
      />
      <Button type="new" btn-class="btn-sm me-2 text-end" btn-text="pipelines.create" @click="openPipelineModal" ></Button>
    </template>
    <template #cardBody>
      <div class="table-responsive row">
        <table class="table align-middle table-row-bordered fs-6 gy-3 gx-4" id="pipelines_datatable">
            <thead>
              <tr class="fw-bold fs-6" style="background-color: #F2F3F4;">
                <th class="min-w-100px">{{ $t('pipelines.label.opportunity_closing_date') }}</th>
                <th class="min-w-150px">{{ $t('pipelines.label.label') }}</th>
                <th class="min-w-150px">{{ $t('crm.pipeline.label.amount') }} ({{ currency }})</th>
                <th class="min-w-150px">{{ $t('pipelines.label.state') }}</th>
                <th class="min-w-50px">{{ $t('label.action') }}</th>
              </tr>
            </thead>
          </table>
      </div>
    </template>
  </Card>
  <CreateOpportunity id="create_pipeline_modal" :form="createOpportunityForm" @load="fetchDatas" />
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import i18n from "@/plugins/i18n";
import Ls from "@/services/ls";
import {BASE_URL} from "@/services/api";
import {formattedLocalAmount} from "@/mixins/Helpers";
import {useRouter, useRoute} from "vue-router";
import {useLocales} from "@/composables/locales";
import {useHasAbility} from "@/composables/hasAbility";
import Card from "@/Modules/CRM/components/Card";
import Button from "@/components/Button.vue";
import {useSwal} from "@/composables/useSwal";
import ButtonDropdownFilter from "@/components/ButtonDropdownFilter.vue";
import CreateOpportunity from "@/Modules/CRM/components/Modals/Pipeline/CreateOpportunity.vue";

const router = useRouter()
const route = useRoute()

const store = useStore()
const { can } = useHasAbility()
const {locale, datatableLocaleUrl} = useLocales()
const {showSwal} = useSwal()

const props = defineProps({
  contactStats: Object,
  query: Object,
  currency: String
})

const table = ref()

const filter = ref({
  status: "",
  seller: ''
})

const createOpportunityForm = ref({
  id: null,
  name: "",
  expected_income: "0",
  creation_date: null,
  closing_date: null,
  opportunity_source_id: "",
  contact_id: "",
  description: "",
  priority: 0,
  opportunity_state_id: '',
  products: [],
  collaborators: [],
  tags: [],
  files: []
})

const opportunityStatus = computed(() => store.getters['pipelines/pipelineStatusEnum'])
const filterOpportunityStatus = computed(() => opportunityStatus.value.filter(status => status.value !== 'NEW'))
const salesPersons = computed(() => store.getters['pipelines/getSalesPersons'])

watch(() => locale.value, (value) => {
  nextTick(() => fetchDatas())
}, { deep: true })

watch(() => filter.value.status, (newValue) => {
  table.value.ajax.url(`${BASE_URL}/get/opportunities/datatables?startDate=${props.query.start_date}&endDate=${props.query.end_date}&contact_id=${route.params.id}&status=${filter.value.status}&seller_id=${filter.value.seller}`).load()
})

watch(() => filter.value.seller, (newValue) => {
  table.value.ajax.url(`${BASE_URL}/get/opportunities/datatables?startDate=${props.query.start_date}&endDate=${props.query.end_date}&contact_id=${route.params.id}&status=${filter.value.status}&seller_id=${filter.value.seller}`).load()
})

watch(() => [props.query.start_date, props.query.end_date], (newValue) => {
  table.value.ajax.url(`${BASE_URL}/get/opportunities/datatables?startDate=${props.query.start_date}&endDate=${props.query.end_date}&contact_id=${route.params.id}&status=${filter.value.status}&seller_id=${filter.value.seller}`).load()
})

function fetchDatas() {
  const AUTH_TOKEN = Ls.get('auth.token')

  table.value = $("#pipelines_datatable").DataTable({
    destroy: true,
    responsive: !0,
    searchDelay: 500,
    processing: !0,
    serverSide: !0,
    pageLength: Ls.get('pageLength') ?? 5,
    lengthMenu: [5, 10, 25, 50, 75, 100 ],
    order: [[ 1, 'asc']],
    ajax: {
      url: BASE_URL + `/get/opportunities/datatables?startDate=${props.query.start_date}&endDate=${props.query.end_date}&contact_id=${route.params.id}&status=${filter.value.status}&seller_id=${filter.value.seller}`,
      type: "GET",
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", "Bearer " + AUTH_TOKEN);
      }
    },
    columns: [
      { data: "closing_date" }, { data: "name" }, { data: "expected_income" }, { data: "opportunityState" }, { data: "Actions", responsivePriority: -1 }
    ],
    columnDefs: [

      {
        targets: -5,
        orderable: false,
        className: "dt-center clickable" ,
        render: function (t, a, e, l) {
          const date = t ? t : '---'
          return '<span data-id="'+ e.id +'">' + date + "</span>";
        },
      },

      {
        width:'25%',
        targets: -4,
        order: "desc",
        className: "dt-center clickable" ,
        render: function (t, a, e, l) {
            return `<span data-id="${e.id}">${t}${e.status == "WON" ? `<i class="emojione-monotone--money-with-wings fs-2 text-primary me-2 mx-4" data-id="${e.id}"></i>` : e.status == "LOST" ?`<i class="fa fa-heart-broken text-danger me-2 fs-2 mx-4" data-id="${e.id}"></i>` :''}</span>`; 
        },
      },
      {
        targets: -3,
        order: "desc",
        className: "dt-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="'+ e.id +'">' + t + "</span>";
        },
      },

      {
        targets: -2,
        orderable: false,
        className: "dt-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="'+ e.id +'">' + formattedLocalAmount(t)  + "</span>";
        },
      },


      {
        targets: -1,
        "className": "dt-center text-center",
        title: "Actions",
        orderable: !1,
        render: (t, a, e, l) => {
          let render=""
          if(can('invoice_view')){
            render += `<a href="javascript:;" data-row="${l.row}" data-id="${e.id}" data-name="${e.name}" class="btn btn-sm btn-clean btn-icon show_btn" title="${i18n.global.t('button.edit')}"><i data-row="${l.row}" data-id="${e.id}" data-name="${e.name}" class="fas fa-edit fs-2" ></i></a>`;
          }
          return render
        },
      },

    ],
    language: {
      url: datatableLocaleUrl.value,
    }
  });

  $(document).on("click", "#pipelines_datatable .show_btn", (e) => {
    router.push({ name: 'pipelines-view', params: { id: e.target.getAttribute('data-id') } })
  });

  $(document).on("click", "#pipelines_datatable .edit_btn", (e) => {
    router.push({ name: 'pipelines-edit', params: { id: e.target.getAttribute('data-id') } })
  });

}

function openPipelineModal(){
  $('#create_pipeline_modal').modal('toggle')
}

function loadData() {
  Promise.all([
    store.dispatch('pipelines/loadPipelineStatusEnum'),
    store.dispatch('pipelines/loadPipelinePrioritiesEnum'),
    store.dispatch('pipelines/loadSalesPersons'),
    fetchDatas()
  ])
}

function filterStatus(value){
  let statistic = opportunityStatus.value.filter(status => status.value === value)
  return statistic
}

onMounted( () => {
  loadData()
})
</script>