<template>
  <div :class="[containerClass]">
    <label v-if="label" :id="`input-${name}-label`" :for="`input${name}`" :class="[labelClass || 'form-label fs-6 fw-bolder text-black', {isRequired: 'required'}]">
      {{ label }} <span v-if="isRequired" class="text-danger"> * </span>
      <a v-if="info" style="padding: 0 !important; margin-left: 5px;" data-bs-toggle="tooltip" data-bs-placement="top" :title="$t(infoText)">
        <i class='fas fa-info-circle' style="font-size: 15px;"></i>
      </a>
    </label>
    <div :class="[IsInputGroup ? 'input-group input-group-solid flex-nowrap' : '']">
      <div :class="{'overflow-hidden flex-grow-1': IsInputGroup}">
        <select :required="isRequired"
                :id="id"
                :key="$i18n.locale"
                class="form-select kt_select2"
                :class="[{ 'invalid': (meta.touched && !meta.valid) }, getClasses(size), selectClass] "
                :name="name"
                v-model="value"
                data-control="select2"
                :data-search="''+showSearch"
                :data-dropdown-parent="parentId ? parentId : ''"
                :multiple="multiple  ? 'multiple' : false"
                :disabled="isDisabled"
                :data-placeholder="placeholderText">
          <option></option>
          <slot />
        </select>
      </div>
      <span v-show="IsInputGroup" :id="`input-${name}-addon`" @click.prevent="clickAddon" class="input-group-text">
        <span class="svg-icon svg-icon-2 px-3 py-3 text-center">
            <slot name="custom"/>
        </span>
      </span>
    </div>
    <span class="text-danger" v-show="errorMessage"> {{ errorMessage }} </span>
  </div>
</template>


<script>

import {nextTick} from "vue";
import {useField} from "vee-validate";
import Button from "@/components/Button";

export default {
  name: 'Select2',
  components: {Button},
  data() {
    const { handleChange, value, handleBlur, errorMessage, meta } = useField(() => this.name, undefined, {
      syncVModel: true,
    })
    return {
      handleChange,
      value,
      handleBlur,
      errorMessage,
      meta
    }
  },
  props: {
    name: { type: String, required: true },
    parentId: { type: String, default: '' },
    placeholderText: { type: String, default: '' },
    showSearch: { type: Boolean, default: true },
    multiple: { type: Boolean, required: true },
    rules: String,
    labelClass: {type: String, default: "form-label"},
    selectClass: {type: String, default: "form-select form-control-lg form-select-solid"},
    containerClass: {
      type: String,
      default: 'col-12 mt-2 mb-5'
    },
    label: String,
    isDisabled: Boolean,
    isRequired: {type:Boolean, default: true},
    modelValue: String|Array,
    iconText: String,
    IsInputGroup: Boolean,
    icon: String,
    iconDir: String,
    addonClass: String,
    clickAddon: Function,
    info: Boolean,
    infoText: String,
    size: { type: String, default: 'lg' },
  },
  computed: {
    id() {
      return `select-${this.name}-id`
    },
  },
  watch: {
    modelValue : function(value){
      if(value) {
        nextTick(() => {
          this.initSelect2()
        })
      }
    },
    id : function() {
      nextTick(() => {
        this.initSelect2()
      })
    },
    '$i18n.locale' : function(value) {
      if(value) {
        this.$nextTick(() => {
          this.initSelect2()
        })
      }
    },

  },
  mounted() {
    this.initSelect2()
  },
  updated() {
    this.$nextTick(() => {
      this.initSelect2()
    })
  },
  methods: {
    async initSelect2() {
      KTApp.initSelect2()
      const select =  $("#"+this.id)
      select.select2({
        minimumResultsForSearch: this.showSearch ? 2 : Infinity
      })
      if(this.multiple) {
        select.change((e) => {
          let result = [];
          if(e.target.value) {
            for (var i = 0, iLen = e.target.selectedOptions.length; i < iLen; i++) {
              if (e.target.selectedOptions[i].value != '' || e.target.selectedOptions[i].value != undefined) {
                result.push(e.target.selectedOptions[i].value);
              }
            }
          }
          this.$emit('update:modelValue', result);
        });
      } else {
        select.change((e) => {
          this.$emit('update:modelValue', e.target.value);
        });
      }
    },
    getIcon (icon) {
      return icon ? icon : null
    },
    hasIcon (icon) {
      return icon ? "input-group" : null
    },
    getClasses (size) {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      isValidValue = this.meta.touched && !this.meta.valid ? `invalid` : "valid";

      return `${sizeValue} ${isValidValue}`;
    }
  }

}
</script>
<style scoped>
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) {
    padding-top: calc((2.5rem + 2px - 0.8rem)/ 2);
    padding-bottom: calc((2.5rem + 2px - 0.8rem)/ 2);
}
.select2-container .select2-selection--multiple, .kt_select2{
  min-height: 44.89px !important;
}

.select-container {
  position: relative;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--bs-secondary);
    text-align: center;
    white-space: nowrap;
    background-color: #f5f8fa;
    border: 1px solid #e4e6ef;
    border-radius: 0.475rem;
}
</style>
