import * as types from "./mutation-types";

export const loadPipelineStatusEnum = ({ commit }) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get("get/opportunities/status/enum")
      .then((response) => {
        commit(types.LOAD_PIPELINE_STATUS_ENUM, response.data);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadPipelinePrioritiesEnum = ({ commit }) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get("get/opportunities/priority/enum")
      .then((response) => {
        commit(types.LOAD_PIPELINE_PRIORITY_ENUM, response.data);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadPipelineHistoryContextEnum = ({ commit }) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get("get/opportunities/history/context/enum")
      .then((response) => {
        commit(types.LOAD_PIPELINE_HISTORY_CONTEXT_ENUM, response.data);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loadPipelineStates = ({ commit }) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get("get/opportunityStates/collection")
      .then((response) => {
        commit(types.LOAD_PIPELINE_STATES, response.data.data);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createPipelineState = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post("store/opportunityState", data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updatePipelineState = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`update/${data.id}/opportunityState`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createPipeline = ({ commit, dispatch, state }, data) => {
    let formData = new FormData();
    data.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });
    data.products.forEach((product, index) => {
        formData.append(`products[${index}]`, product);
    });
    data.tags.forEach((tag, index) => {
        formData.append(`tags[${index}]`, tag);
    });
    data.collaborators.forEach((collaborator, index) => {
        formData.append(`collaborators[${index}]`, collaborator);
    });
    formData.append('name', data.name)
    formData.append('expected_income', data.expected_income)
    formData.append('opportunity_state_id', data.opportunity_state_id)
    formData.append('closing_date', data.closing_date)
    formData.append('priority', data.priority)
    formData.append('description', data.description)
    formData.append('contact_id', data.contact_id)
    formData.append('creation_date', data.creation_date)
    formData.append('opportunity_source_id', data.opportunity_source_id)
    formData.append('_method', 'POST')

    return new Promise((resolve, reject) => {
        window.axios.post('store/opportunity', formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createPipelineSimple = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post("store/opportunity", data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getPipeline = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`show/${id}/opportunity`)
      .then((response) => {
        commit(types.SET_CURRENT_PIPELINE, response.data);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updatePipeline = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`update/${data.id}/opportunity`, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const uploadPipelineFiles = ({ commit, dispatch, state }, data) => {
    let formData = new FormData();

    data.files.forEach((file, index) => {
        if(file instanceof File){
            formData.append(`files[${index}]`, file);
        }
    });


    return new Promise((resolve, reject) => {
        window.axios.post(`upload/opportunity/${data.id}/files`, formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const removePipelineFile = ({ commit, dispatch, state }, id) => {
    let formData = {opportunity_file_id: id}

    return new Promise((resolve, reject) => {
        window.axios.delete(`delete/opportunity/${id}/file`, formData, {headers: {}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPipelinesByStates = ({ commit }, query) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(
        `get/opportunities/stats?startDate=${query.startDate}&endDate=${query.endDate}&contact_id=${query.contact}&search=${query.search}&status=${query.status}&seller_id=${query.seller}&priority=${query.priority}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changeOpportunityState = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .patch(`change/opportunity/${data.id}/state`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        // reject(err)
      });
  });
};

export const loadPipelinesByForecast = ({ commit }, query) => {
  return new Promise((resolve, reject) => {
    window.axios
        .get(
            `get/opportunities/forecast?contact_id=${query.contact}&search=${query.search}&year=${new Date(query.year).getFullYear()}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
  });
};

export const addUsersPipeline = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('add/opportunity/'+ data.opportunity_id +'/followers', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const removeUsersPipeline = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
      window.axios.delete('delete/opportunity/'+ data.opportunity_id +'/follower', {params: data}).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const changeStatusPipeline = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/opportunity/'+ data.opportunity_id +'/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeStatePipeline = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('change/opportunity/'+ data.opportunity_id +'/state', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeInLostPipeline = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('set/opportunity/'+ data.opportunity_id +'/lost/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addFeeling = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('add/opportunity/'+ data.opportunity_id +'/feeling', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPipelineFeelingEnum = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get("get/feeling/icon")
            .then((response) => {
                commit(types.LOAD_PIPELINE_FEELING_ENUM, response.data);
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const loadSalesPersons = ({ commit }) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get("get/sellers")
      .then((response) => {
        commit(types.LOAD_SALES_PERSONS, response.data);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const archivePipeline = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
      window.axios.patch('archive/opportunity/'+ data.opportunity_id, data).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const exportPipeline = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
      window.axios.get('export/opportunities', { responseType: 'blob' }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'opportunities.xlsx');
          document.body.appendChild(link);
          link.click();
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}