<template>
  <div :class="[containerClass]">
    <label v-if="label" :id="`input-${name}-label`" :for="`input${name}`" :class="[labelClass || 'form-label fs-6 fw-bolder text-black', {isRequired: 'required'}]">
      {{ label }} <span v-if="isRequired" class="text-danger"> * </span>
    </label>
    <div :class="[
              { 'position-relative': (type === 'password' || $slots.rightAddon || $slots.leftAddon) },
              IsInputGroup ? 'input-group' : ''
              ]">
      <slot name="leftAddon" />
      <span v-show="iconDir === 'left'" :id="`input-${name}-addon`" class="input-group-text" :class="addonClass">
       {{ iconText }}
        <i :class="getIcon(icon)"></i>
      </span>
      <input
          :type="type === 'password' ? inputType : type"
          class="form-control"
          :class="[
              { 'invalid': (meta.touched && !meta.valid) },
                getClasses(size), inputClass]"
          :name="name"
          :id="`input-${name}`"
          v-model="value"
          :placeholder="placeholder"
          :required="isRequired"
          :aria-label="label"
          :aria-describedby="`input-${name}-addon`"
          :disabled="isDisabled"
          :rules="rules"
          :min="min"
          :max="max"
          :readonly="readonly"
      />
      <slot name="rightAddon" />
      <span v-if="type === 'password'" class="position-absolute border-0 bg-transparent end-0 top-50 translate-middle" @click="togglePasswordVisibility">
        <i :class="eyeIconClass"></i>
      </span>
      <span v-show="iconDir === 'right'" :id="`input-${name}-addon`" class="input-group-text" :class="addonClass">
       {{ iconText }}
        <i :class="getIcon(icon)"></i>
      </span>
      <slot/>
    </div>
    <span class="text-danger" v-show="errorMessage"> {{ errorMessage }} </span>
  </div>
</template>

<script setup>

import { useField } from 'vee-validate';
import {computed, ref} from "vue";
const props = defineProps( {
  size: {
    type: String,
    default: "lg",
  },
  icon: String,
  iconDir: String,
  name: String,
  id: String,
  modelValue: String,
  placeholder: String,
  type: {type:String, default: "text"},
  isRequired: {type:Boolean, default: true},
  inputClass: {type:String, default: "form-control form-control-solid"},
  labelClass: {type:String, default: "form-label"},
  containerClass: {
    type: String,
    default: 'col-12 mt-2 mb-5'
  },
  label: String,
  isDisabled: Boolean,
  rules: String,
  iconText: String,
  IsInputGroup: Boolean,
  addonClass: String,
  min: Number,
  max: Number,
  readonly: Boolean,
})

const showPassword = ref(false)

const { handleChange, value, handleBlur, errorMessage, meta } = useField(() => props.name, undefined, {
  syncVModel: true,
});

const inputType = computed(() => showPassword.value ? 'text' : 'password')

const eyeIconClass = computed(() => showPassword.value ? 'fas fa-eye-slash' : 'fas fa-eye')

function getClasses (size) {
  let sizeValue, isValidValue;

  sizeValue = size ? `form-control-${size}` : null;

  isValidValue = meta.touched && !meta.valid ? `invalid` : "valid";

  return `${sizeValue} ${isValidValue}`;
}

function getIcon (icon) {
  return icon ? icon : null
}
function hasIcon (icon) {
  return icon ? "input-group" : null
}

function togglePasswordVisibility() {
  showPassword.value = !showPassword.value;
}

</script>
<style scoped>
input.invalid {
  border: 1px solid red;
}
</style>
