import * as types from './mutation-types'

export default {
  [types.ADD_PRODUCTS](state, data) {
    state.products = data
  },
  [types.ADD_PRODUCT](state, data) {
    state.products.push(data)
  },

  [types.ADD_STORE_PRODUCT_SALE_ELEMENTS](state, {id, product_sale_elements}) {
    let storeProductSaleElements = state.storeProductSaleElements[id] || []
    storeProductSaleElements = product_sale_elements
    state.storeProductSaleElements = {...state.storeProductSaleElements, [id]: storeProductSaleElements}
  },
  [types.SET_IMAGE] (state, {id, image}) {
    state.products = state.products.map((product) => { if (product?.id == id) {product.image = image} return product })
  },

  [types.SET_MULTI_IMAGE] (state, {id, images}) {
    state.products = state.products.map((product) => { if (product.id == id) {product.images = images} return product })
  },

  [types.LOAD_PRODUCT_SALE_ELEMENTS](state, data) {
    state.product_sale_elements = data.product_sale_element
  },
  [types.LOAD_PRODUCT_SALE_ELEMENTS_BY_CATEGORIES](state, data) {
    state.product_sale_elements_by_categories = data.data
  },
  [types.SET_PRODUCT_LOTS](state, data) {
    state.lots = data
  },
  [types.ADD_TYPES](state, data) {
    state.types = data
  },
}
