import * as types from './mutation-types'

export default {
  [types.LOAD_INVOICES](state, data) {
    state.invoices = data.data
  },

  [types.ADD_INVOICE](state, data) {
    state.invoices.push(data)
  },

  [types.DELETE_INVOICE](state, invoiceId) {
    state.invoices = state.invoices.filter(el => el.id !== invoiceId)
  },

  [types.SET_CURRENT_INVOICE](state, invoice) {
    state.currentInvoice = invoice.data
  },

  [types.LOAD_INVOICE_STATUS](state, data) {
    state.invoiceStatus = data
  },
}
