import * as types from './mutation-types'


export const loadEstimates = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/estimates/collection').then((response) => {
            commit(types.LOAD_ESTIMATES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const getEstimateById = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/estimate/'+id).then((response) => {
            commit(types.SET_CURRENT_ESTIMATE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createEstimate = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/estimate', data).then((response) => {
            resolve(response)
            commit(types.ADD_ESTIMATE, response.data.estimate)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateEstimate = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/estimate', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteEstimate = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/estimate').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const sendEstimate = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('send/estimate/'+ data.estimateId + '/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const cancelEstimateMail = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.post('cancel-email/estimate/'+ id + '/email').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadEstimate = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/estimate/pdf/${id}`, {
            responseType: 'blob'
        }).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadEstimateStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('estimate/status/enum').then((response) => {
            commit(types.LOAD_ESTIMATE_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const validateEstimate = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('set/estimate/' + data.estimate_id + '/validated', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const generateInvoice = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/estimate/' + data.estimate_id + '/invoice', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeEstimateStatus = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/estimate/' + data.estimate_id + '/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}