<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('permission', [
      'permissions'
    ])
  },
  methods: {
    $can (permissionName) {
      return this.permissions?.indexOf(permissionName) !== -1
    },

    $accessMenu(permissionNames){
      let access = false
      if(permissionNames.length === 0){
        access = true
      }
      for(let perm of permissionNames){
        if(!access){
          access = this.permissions?.indexOf(perm) !== -1
        }
      }
      return access
    }
  }
}
</script>
