import * as types from "./mutation-types";
import {createFormData} from "@/mixins/Helpers";

export const loadEmailActivityTypeEnum = ({ commit }, opportunityId) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get(`get/emailActivity/type/enum`)
            .then((response) => {
                commit(types.LOAD_EMAIL_ACTIVITY_TYPE_ENUM, response.data);
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const loadCallActivityTypeEnum = ({ commit }, opportunityId) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get(`get/callActivity/type/enum`)
            .then((response) => {
                commit(types.LOAD_CALL_ACTIVITY_TYPE_ENUM, response.data);
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const loadActivityTypes = ({ commit }, opportunityId) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get(`get/typeActivities/collection`)
            .then((response) => {
                commit(types.LOAD_ACTIVITY_TYPES, response.data);
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const createActivity = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post("store/activity", data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updateActivity = ({ commit, dispatch, state }, {id, data}) => {
    const formData = createFormData(data)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
        window.axios
            .post(`update/${id}/activity`, formData)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const deleteActivity = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios
            .delete(`delete/${id}/activity`)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const loadOpportunityActivities = ({ commit }, opportunityId) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`get/opportunity/${opportunityId}/activities`)
      .then((response) => {
        commit(types.LOAD_OPPORTUNITY_ACTIVITIES, response.data);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
