import { createI18n } from 'vue-i18n';
import merge from 'lodash.merge'
import en from './en.json';
import fr from './fr.json';

import crmFr from '@/Modules/CRM/lang/fr.json';
import crmEn from '@/Modules/CRM/lang/en.json';

const french = merge({}, fr, crmFr);
const english = merge({}, en, crmEn);


const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('locale') || 'fr',
  messages: {
    en: english,
    fr: french,
  },
});

export default i18n;
