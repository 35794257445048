import * as types from './mutation-types'

export default {
  [types.ADD_BRANDS](state, data) {
    state.brands = data.brands
  },
  [types.ADD_BRAND] (state, data) {
    state.brands.push(data)
  },
}
