import * as types from './mutation-types'


export const loadBanks = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/banks/collection').then((response) => {
            commit(types.LOAD_BANK, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createBank = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/bank', data).then((response) => {
            commit(types.ADD_BANK, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })

}


export const updateBank = ({commit}, banks) => {

    return new Promise((resolve, reject) => {
        
        window.axios.patch('update/' + banks.id + '/bank',banks).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
