import * as types from './mutation-types'

export const generalInformation = ({commit, dispatch, state}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/society/infos').then((response) => {
            resolve(response)
            commit(types.GET_COMPANY_INFOS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const loadCurrencies = ({commit, dispatch, state}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/currencies/collection').then((response) => {
            resolve(response)
            commit(types.GET_CURRENCIES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}



