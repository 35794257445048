import * as types from './mutation-types'

export default {
  [types.ADD_ATTRIBUTS](state, data) {
    state.attributs = data.attributs
  },

  [types.ADD_ATTRIBUT](state, data) {
    state.attributs.push(data)
  },
}
