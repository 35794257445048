import * as types from './mutation-types'




export const loadSmtp = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/smtpsettings', data).then((response) => {
           
            commit(types.ADD_SMTP, response.data)
            
            
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeSmtp = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        
        window.axios.post('store/smtpsettings', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}