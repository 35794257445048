import * as types from './mutation-types'

export default {
  [types.ADD_TAXES](state, data) {
    state.taxes = data.taxes
  },
  [types.ADD_TAXE](state, data) {
    state.taxes.push(data)
  },
}
