import * as types from './mutation-types'

export default {

  [types.LOAD_PROFILES] (state, roles) {
    state.roles = roles
  },

  [types.ADD_ROLE](state, data) {
    state.roles.push(data)
  },
}

