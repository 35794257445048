<template>
 <div class="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column  bg-white"
    id="kt_create_account_stepper">
    <div class="d-flex flex-column flex-lg-row-fluid py-10" style="height: 100vh !important;">
      <div class="d-flex align-items-center ms-auto me-1 me-lg-3">
        <LangDropdown />
      </div>
      <div class="d-flex flex-center flex-column flex-column-fluid">
        <div class="w-lg-700px p-10 p-lg-15 mx-auto">
          <form class="my-auto pb-5" novalidate="novalidate" id="kt_create_account_form">
            <div class="current" data-kt-stepper-element="content">
              <div class="w-100 scroll-y me-n7 pe-7">
                <div class="pb-10 pb-lg-15">
                  <h1 class="fw-bolder fs-2hx d-flex align-items-center text-primary">
                    {{ $t('appointment_user.join_demo_crm') }}
                  </h1>
                  <div class="text-muted fw-bold fs-6">
                    Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent.
                  </div>
                </div>
                <div class="fv-row mb-10">
                  <label class="form-label mb-3">{{ $t('appointment_user.object') }}</label>
                  <input readonly type="text" :value="appointment?.title" class="form-control form-control-lg form-control-solid" ref="subject" name="subject" />
                </div>
                <div class="fv-row mb-10">
                  <label class="form-label mb-3">{{ $t('appointment_user.location') }}</label>
                  <input readonly type="text" :value="appointment?.location" class="form-control form-control-lg form-control-solid" ref="location" name="location" />
                </div>
                <div class="row mb-10">
                  <div class="col-md-6">
                    <div class="d-flex justify-content-between fv-row mb-5">
                      <span class="text-start mt-2">{{ $t('appointment_user.programming_for') }}</span>
                      <span class="badge badge-light-primary text-end p-3">{{appointment?.start_time}}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex justify-content-between fv-row mb-5">
                      <span class="text-start mt-2">{{ $t('appointment_user.to') }}</span>
                      <span class="badge badge-light-primary text-end p-3">{{appointment?.end_time}}</span>
                    </div>
                  </div>
                </div>
                <div class="fv-row mb-10" v-if="appointment?.description">
                  <textarea readonly class="form-control form-control-lg form-control-solid" rows="5">
                    {{appointment?.description}} 
                  </textarea>
                </div>
                <div class="text-center mt-10">
                  <Button type="new" btn-class="btn-md" btn-text="appointment_user.participants" @click.prevent="openMembers"></Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column flex-lg-row-fluid justify-content-center py-10 position-xl-relative element">
      <div class="w-lg-700px px-10 py-10 p-lg-15 mx-auto">
        <div class="text-center mb-10">
          <a href="#" class="mb-10 mb-lg-20">
            <img alt="Logo" src="/assets/media/logo.png" class="h-50px" />
          </a>
        </div>
        <p class="text-center mb-20">
          Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent.
        </p>

        <div class="bg-white p-5 mb-20">
          <Calendar id="calendarRdvMonthId" :size="size" />
        </div>

        <p class="text-center">
          <span>{{$t('appointment_user.text_confirm')}}</span><br>
          <span class="text-primary">{{$t('appointment_user.use_condition')}}</span>
          <span>{{$t('appointment_user.and')}}</span>
          <span class="text-primary">{{$t('appointment_user.confidentially')}}</span>
        </p>
        <div class="d-flex justify-content-between text-center mt-10">
          <Button type="new" btn-class="btn-md me-3" btn-text="appointment_user.confirm_event" :isLoading="isLoading" @click.prevent="confirmAppointment"></Button>
          <OutlineButton type="primary" :border="true" btn-class="btn-md" btn-text="appointment_user.dont_participe" :isLoading2="isLoading2" @click.prevent="noConfirmAppointment"></OutlineButton>
        </div>
      </div>
    </div>
  </div>
  <InviteMembers id="show_members_list" :members="members"/>
</template>
<script setup>
import LangDropdown from "@/components/LangDropdown";
import Button from "@/components/Button.vue";
import {ref, onMounted} from "vue"
import Calendar from "@/components/Calendar.vue"
import OutlineButton from "@/components/OutlineButton.vue";
import InviteMembers from "@/Modules/CRM/components/Modals/Appointment/InviteMembersModal.vue"
import {useStore} from "vuex";
import {useSwal} from "@/composables/useSwal";
import { useRoute } from 'vue-router';

const store = useStore()
const {showSwal} = useSwal()
const route = useRoute()

const size = ref({
    contentHeight: 150,
    height: 250
})
const isLoading = ref(false)
const isLoading2 = ref(false)
const appointment = ref({})
const members = ref({})

function openMembers(){
  $('#show_members_list').modal('toggle')
}

function confirmAppointment(){
    showSwal(
        '',
        i18n.global.t('appointments.confirm_appointment'),
        'info',
        '#0072CE'
    ).then( async (result) => {
        if (result.value) {
            try {
                isLoading.value = true
                store.dispatch('appointments/setParticipantAvailability', {
                  appointment_id: route.query.appointment_id, 
                  participant_id: route.query.participant_id, 
                  available_status: "AVAILABLE"
                })
                  .then((response) => {
                    isLoading.value = false
                    window.toastr['success'](i18n.global.t('notification.appointment_confirmed'))
                  })
                  .catch(err => {
                    isLoading.value = false
                  })
            }catch (e) {

            }
        }
    });
}

function noConfirmAppointment(){
    showSwal(
        '',
        i18n.global.t('appointments.no_confirm_appointment'),
        'info',
        '#0072CE'
    ).then( async (result) => {
        if (result.value) {
            try {
                isLoading2.value = true
                store.dispatch('appointments/setParticipantAvailability', {
                  appointment_id: route.query.appointment_id, 
                  participant_id: route.query.participant_id, 
                  available_status: "UNAVAILABLE"
                })
                  .then((response) => {
                    isLoading2.value = false
                    window.toastr['success'](i18n.global.t('notification.appointment_no_confirmed'))
                  })
                  .catch(err => {
                    isLoading2.value = false
                  })
            }catch (e) {

            }
        }
    });
}

onMounted(() => {
  store.dispatch('appointments/getAppointment', route.query.appointment_id).then((res) => {
    appointment.value = res.data.data
    members.value = res.data?.data?.participants
  })
})
</script>

<style scoped>
.element {
  background: linear-gradient(rgba(0, 114, 206, 0.1), rgba(240, 120, 23, 0.1));
  box-shadow: rgba(0, 0, 0, 0.15) 3px 0px 17px;
  height: 100vh;
}
</style>
