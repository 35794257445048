import * as types from './mutation-types'

export default {
  [types.ADD_STEPS](state, data) {
    state.steps = data.data
  },
  [types.ADD_STEP] (state, data) {
    state.steps.push(data)
  }
}
