import * as types from './mutation-types'

export default {
  [types.ADD_TAGS](state, data) {
    state.tags = data.tags
  },
  [types.ADD_TAG] (state, data) {
    state.tags.push(data)
  },
}
