<template>
  <div class="modal fade" tabindex="-1" :id="'contactAddressModal' + id" >
    <div class="modal-dialog modal-md">
      <form @submit.prevent="saveForm" class="form">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="action === 'create'" class="modal-title">{{$t("contacts.new_address")}}</h5>
            <h5 v-if="action === 'edit'" class="modal-title">{{$t("contacts.edit_address")}}</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
              <span class="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                        fill="currentColor"/>
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                        fill="currentColor"/>
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body">
            <RequiredText />
            <div class="form">
              <div class="row mb-2">
                <div class="col-md-12">
                  <label class="">{{ $t('contacts.adress') }} </label>
                  <div class="form-group mb-2">
                    <input v-model="form.street" :placeholder="$t('contacts.placeholder.street')" type="text"
                           class="form-control form-control-solid">
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-6">
                  <div class="form-group mb-2 row">
                    <label class="">{{ $t('auth.country') }} </label>
                    <select :name="'country'+id"  :data-dropdown-parent="'#contactAddressModal' + id"  data-search="true" :id="'country'+id"  :data-placeholder="$t('auth.country')" class="form-select form-select-solid kt_select2"
                            data-control="select2" v-model="form.country">
                      <option></option>
                      <option v-for="country in getCountries" :key="country.id" :value="country.id">
                        {{country.name}}
                      </option>
                    </select>
                  </div>
                </div>
                <Select2
                    :parent-id="'#contactAddressModal'+id"
                    containerClass="col-md-6 mb-2"
                    :name="'contactAddressCity'"
                    :multiple="false"
                    :label="$t('auth.city')"
                    labelClass="text-black"
                    selectClass="form-select form-select-solid"
                    :isRequired="false"
                    :placeholderText="$t('contacts.placeholder.city')"
                    v-model="form.city_id">
                  <option v-for="city in cites" :key="city.id" :value="city.id">
                    {{ city.name }}
                  </option>
                </Select2>
              </div>
              <div class="form-group mb-2">
                <label>{{ $t('contacts.placeholder.zipcode') }} </label>
                <input type="text" v-model="form.zipcode" class="form-control form-control-solid"
                       :placeholder="$t('contacts.placeholder.zipcode')">
              </div>
            </div>
          </div>
          <div class="modal-footer">
          </div>
          <div class="modal-footer">
            <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.close" @click="closeModal"></Button>
            <Button btn-class="btn-sm" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :is-loading="isLoading"></Button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import Select2 from "@/components/Select2.vue";
import RequiredText from "@/components/RequiredText"
import Button from "@/components/Button.vue"

export default {
  components: {Select2, RequiredText, Button},

  props: {
    address: {type: Object, required: true},
    getCountries: {type: Array, required: false},
    action: {type: String, default: 'create'},
    contact: {type: String, default: ''},
    id: {type: String, default: "1"}
  },

  data() {
    return {
      form: {
        id: null,
        gender: null,
        street: null,
        street2: null,
        city_id: null,
        zipcode: null,
        country: null
      },
      isLoading: false
    }
  },

  watch: {
    '$i18n.locale': function (value) {
      this.$nextTick(() => {
        $(".kt_select2").select2({
          placeholder: this.$t('auth.country')
        });
      })
    },
    address: function (value) {
      this.form = value
    }
  },

  mounted() {
    this.form = this.address
    this.loadCities()
    $("#kt_hbd").flatpickr();

    KTApp.initSelect2()

    $('#country'+ this.id).select2()

    $('body select[data-control="select2"]').select2()


    $('body select[data-control="select2"]').change((e) => {
      switch (e.target.name) {
        case 'country' + this.id :
          this.form.country = e.target.value
          break;
        case 'gender' + this.id :
          this.form.gender = e.target.value
          break;
      }
    });

    $('#contactAddressModal' + this.id).on('shown.bs.modal', (e) => {
      KTApp.initSelect2()
      $('body select[data-control="select2"]').select2()

    })

  },

  computed: {
    contactNamePlaceholder() {
      return i18n.global.t('contacts.fullname')
    },
    ...mapGetters('cities', [
      'getCities'
    ]),

    cites () {
      if(this.form.country)
        return this.getCities.filter(el => el.country_id == this.form.country)

      return this.getCities
    }
  },

  methods: {
    ...mapActions('cities', [
      'loadCities'
    ]),
    ...mapActions('contacts', [
      'updateAddress'
    ]),

    pad(s) {
      return (s < 10) ? '0' + s : s;
    },

    convertDate(d) {
      return [d.getFullYear(), this.pad(d.getMonth() + 1), this.pad(d.getDate()),].join('-')
    },

    timeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },

    async saveForm() {
      this.isLoading = true

      try {
        let response = await this.updateAddress({address: this.form, contact: this.contact, action: this.action})
        $('#contactAddressModal' + this.id).modal('toggle')
        this.form = {
          id: null,
          gender: null,
          street: null,
          street2: null,
          city_id: null,
          zipcode: null,
          country: null
        }

        this.emitValue(response.data.address)

        window.toastr['success']('Enrégistrement effectué avec succès')
        this.isLoading = false
        return true

      } catch (e) {
        this.isLoading = false
      }
    },

    emitValue(address){
      if(this.action === 'edit' || this.action === 'default'){
        this.$emit('addressUpdated', address)
      }else {
        this.$emit('addressCreated', address)
      }
    },
    closeModal(){
      $('#contactAddressModal' + this.id).modal('toggle')
    }
  },


}
</script>

<style scoped>

.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #1e1e2d;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #1e1e2d;
}
</style>
