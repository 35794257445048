<template>
  <BaseGlobalLoader v-if="isLoaded"/>
  <router-view v-else />
</template>


<script setup>
import 'nprogress/nprogress.css'
import '../public/assets/css/nprogress.custom.css'
import BaseGlobalLoader from '@/components/BaseGlobalLoader'
import { computed } from 'vue';
import {useStore} from "vuex";
const store = useStore()
const isLoaded = computed(()=> store.getters['isAppLoaded'])
</script>
<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>
