<template>
    <div class="modal fade" :id="id" tabindex="-1">
      <div class="modal-dialog" >
        <form class="form">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('appointments.members') }}</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" @click="closeModal">
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                            fill="currentColor" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                            fill="currentColor" />
                    </svg>
                  </span>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="scroll h-300px mt-5">
                    <a class="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1" v-for="(participant, index) in participants" :key="index">
                      <div :class="'symbol-35px me-2'" class="symbol symbol-circle">
                        <span v-if="participant?.fullname && !participant?.avatar_url" class="symbol-label fw-bolder" style="background-color: rgba(0, 114, 206, 0.25); color: rgba(0, 114, 206, 1);">{{initial(participant?.fullname)}}</span>
                        <img v-if="participant?.avatar_url" class="img-size rounded-circle" :alt="participant?.fullname || participant?.email" :src="participant.avatar_url" />
                      </div>
                      <div class="row fw-bold">
                        <span v-if="participant?.fullname" class="fs-6 text-gray-800 me-2">{{participant?.fullname}}</span>
                        <span v-if="participant?.email && !participant?.fullname" class="fs-6 text-gray-800 me-2">{{participant?.email}}</span>
                      </div>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
<script setup>    
  import {ref, watch} from "vue";
  import {useInitialName} from "@/composables/InitialName"

  const props = defineProps({
    id: {
      type: String,
      required: true
    },
    members: Object
  })

  const {initial} = useInitialName()


  const participants = ref([])

  function closeModal(){
      $('#'+props.id).modal('toggle')
  }

  watch(() => props.members, (newValue) => {
    let data = []
    if(newValue){
        newValue?.filter((item) => {
            data.push({fullname: item?.participant?.fullname, email: item?.participant?.email, avatar_url: item?.participant?.hasOwnProperty('department_id') ? null : item?.participant?.avatar_url})
        })
    }
    participants.value = data
  })
</script>

<style scoped></style>
  