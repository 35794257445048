<template>
    <div class="form-group row">
        <div class="col-lg-12">
            <div class="dropzone dropzone-queue mb-2" :id="'kt_dropzone_' + id">
                <div class="dropzone-panel mb-lg-0 mb-2">
                    <a class="dropzone-select btn btn-sm btn-primary me-2">{{$t('auth.add_file')}}</a>
                    <a class="dropzone-remove-all btn btn-sm btn-light-primary">{{ $t('auth.delete_all') }}</a>
                </div>
                <div class="dropzone-items wm-200px">
                    <div class="dropzone-item" style="display:none">
                        <div class="dropzone-file">
                            <div class="dropzone-filename" title="nom_de_fichier.jpg">
                                <span data-dz-name>nom_de_fichier.jpg</span>
                                <strong>(<span data-dz-size>340kb</span>)</strong>
                            </div>
                            <div class="dropzone-error" data-dz-errormessage></div>
                        </div>
                        <div class="dropzone-progress">
                            <div class="progress">
                                <div
                                    class="progress-bar bg-primary"
                                    role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" data-dz-uploadprogress>
                                </div>
                            </div>
                        </div>
                        <div class="dropzone-toolbar">
                            <span class="dropzone-cancel" data-dz-remove style="display: none;"><i class="bi bi-x fs-3"></i></span>
                            <span class="dropzone-delete" data-dz-remove><i class="bi bi-x fs-1"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <span class="form-text text-muted">{{$t('auth.file_size')}} {{ this.maxSizes }} {{$t('auth.file_number')}} {{ this.maxFiles}}.</span>
        </div>
    </div>

</template>

<script>

    export default {

      props: {
        id: {required: true},
        maxFiles: {required: true, type: Number},
        maxSizes : {required: true ,type:Number},
        modelValue: {
          type: Array,
          required: true
        }
      },

      data(){
        return {
          content: this.modelValue
        }
      },


      mounted(){
        this.dropzoneFilesUpload()
      },

      methods: {
        dropzoneFilesUpload(){
          let id = '#kt_dropzone_' + this.id;
          let previewNode = $(id + " .dropzone-item");
          previewNode.id = "";
          let previewTemplate = previewNode.parent('.dropzone-items').html();
          previewNode.remove();

          let myDropzone4 = new Dropzone(id, { 
              url: window.api_url, 
              parallelUploads: 20,
              previewTemplate: previewTemplate,
              maxFilesize: this.maxSizes, 
              maxFiles: this.maxFiles,
              autoQueue: false, 
              previewsContainer: id + " .dropzone-items", 
              clickable: id + " .dropzone-select" 
          });

          myDropzone4.on("addedfile", (file) => {
            if(this.maxFiles === 1){
              this.content[0] = file
              this.content = [...this.content];
            }else {
              this.content.push(file)
            }
            this.updateContent()
            $(document).find(id + ' .dropzone-item').css('display', '');
            $(id + " .progress").css('display', 'inline-block');
          });

          myDropzone4.on("removedfile", (file) => {
            let files = this.content.filter(arrayFile => arrayFile !== file)
            this.content = files
            this.updateContent()
            if(myDropzone4.files.length < 1){
                $(id + " .dropzone-upload, " + id + " .dropzone-remove-all").css('display', 'none');
            }
          });
        },

        updateContent () {
          this.$emit('update:modelValue', this.content)
        }
      },
    }
</script>

<style scoped>

</style>
