import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const initialState = {
    contacts: [],
    contactSuppliers: [],
    contactScroll: [],
    contactScrollCount : 0,
    addresses : [],
    contactStats: [],
    

}

export default {
    namespaced: true,

    state: initialState,

    getters: getters,

    actions: actions,

    mutations: mutations
}
