import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import store from '@/store/index'

import {UPDATE_APP_LOADING_STATUS} from '@/store/mutation-types'

import LayoutBasicView from '@/views/Layouts/LayoutBasic'

import LayoutLoginView from '@/views/Auth/LayoutLogin.vue'
import ConnexionView from '@/views/Auth/Connexion.vue'
import ForgotPasswordView from '@/views/Auth/ForgotPassword.vue'
import CreatePasswordView from '@/views/Auth/CreatePassword.vue'
import ResetPasswordView from '@/views/Auth/ResetPassword.vue'
import CreateAccountView from '@/views/Auth/CreateAccount.vue'
import CreateAppointmentView from '@/views/CreateAppointmentView.vue'
import PermissionDeniedView from '@/views/Auth/PermissionDenied.vue'
import NotFoundPageView from '@/views/NotFoundPage.vue'

import ContactListView from '@/views/Contact/Index'
import ContactDetailView from '@/views/Contact/View'
import {isSubDomain} from "@/mixins/Helpers";

import CrmRoutes from "@/Modules/CRM/router/index"

const routes = [
    /*
   |--------------------------------------------------------------------------
   | Authentication Routes
   |--------------------------------------------------------------------------|
   */

    {
        path: '/account',
        name: 'create-account',
        meta: { redirectIfAuthenticated: true, redirectIfTenant: true },
    },
    {
        path: '/create_account',
        name: 'create_account',
        component: CreateAccountView,
        meta: { redirectIfAuthenticated: true, redirectIfTenant: true },
    },
    {
        path: '/appointment/user',
        name: 'create-appointment_user',
        component: CreateAppointmentView,
        meta: { redirectIfAuthenticated: true, requiresTenant: true },
    },
    {
        path: '/',
        component: LayoutLoginView,
        meta: { requiresTenant: true },
        children: [
            {
                path: '/',
                component: ConnexionView,
                meta: { redirectIfAuthenticated: true, image: require('../../public/assets/media/login.png') },
            },
            {
                path: 'login',
                name: 'login',
                component: ConnexionView,
                meta: { redirectIfAuthenticated: true, image: require('../../public/assets/media/login.png') },
            },
            {
                path: 'confirm/:id/:token/create_password',
                component: CreatePasswordView ,
                name: 'create-password',
                meta: { redirectIfAuthenticated: false, image: require('../../public/assets/media/resetPassword.png') },
            },

            {
                path: 'forgot-password',
                component:  ForgotPasswordView ,
                name: 'forgot-password',
                meta: { redirectIfAuthenticated: true, image: require('../../public/assets/media/forgetPassword.png') },
            },
            {
                path: 'reset/:token/password',
                component: ResetPasswordView ,
                name: 'reset-password',
                meta: { redirectIfAuthenticated: true, image: require('../../public/assets/media/resetPassword.png') },
            },

        ]

    },
    /*
    |--------------------------------------------------------------------------
    | Admin Backend Routes
    |--------------------------------------------------------------------------|
    */
    {
        path: '/admin',
        name: 'admin',
        redirect: 'admin/home',
        component: LayoutBasicView,
        meta: { requiresAuth: true, requiresTenant: true },
        children: [
            /*
            |--------------------------------------------------------------------------
            | Admin Backend Routes
            |--------------------------------------------------------------------------|
            */
            {
                path: '/',
                component: () => import('@/views/Home/index.vue'),
                name: 'home',
                meta: { requiresPermission: 'dashboard_view' },
            },
            {
                path: 'home',
                component: () => import('@/views/Home/index.vue'),
                name: 'home-path',
                meta: { requiresPermission: 'dashboard_view' },
            },
            {
                path: 'contacts',
                component: ContactListView,
                name: 'contacts-path',
                meta: { requiresPermission: 'contact_view' },
            },
            {
                path: 'contact/:id/view',
                component: ContactDetailView,
                name: 'contact-view-path',
                meta: { requiresPermission: 'contact_view' },

            },
            {
                path: 'statistic',
                component: () => import('@/views/Statistic/index'),
                name: 'statistics',
                meta: { requiresPermission: 'dashboard_view' },
            },
            {
                path: 'profile',
                component: () => import('@/views/Profil.vue'),
                name: 'profile',
                meta: { requiresPermission: '' },
            },
            {
                path: 'components',
                component: () => import('@/views/ComponentsView.vue'),
                name: 'components',
                meta: { requiresPermission: '' },
            },


            /*
           |--------------------------------------------------------------------------
           | Invoice Routes
           |--------------------------------------------------------------------------|
           */
            {
                path: 'invoices',
                children: [
                    {
                        path: '',
                        component:  () => import('@/views/Invoice/InvoiceList.vue'),
                        name: 'invoices',
                        meta: { requiresPermission: 'invoice_view' },
                    },
                    {
                        path: 'create',
                        component:  () => import('@/views/Invoice/InvoiceDetail.vue'),
                        name: 'invoices-create',
                        meta: { requiresPermission: 'invoice_view' },
                    },
                    {
                        path: 'edit/:id',
                        component:  () => import('@/views/Invoice/InvoiceDetail.vue'),
                        name: 'invoices-edit',
                        meta: { requiresPermission: 'invoice_update' },
                    },
                    {
                        path: 'view/:id',
                        component:  () => import('@/views/Invoice/InvoiceView.vue'),
                        name: 'invoices-view',
                        meta: { requiresPermission: 'invoice_view' },
                    }
                ]
            },


            /*
           |--------------------------------------------------------------------------
           | Items Routes
           |--------------------------------------------------------------------------|
           */
           {
                path: 'items',
                children: [
                    {
                        path: '',
                        component:  () => import('@/views/Items/Product/Index.vue'),
                        name: 'items',
                        meta: {
                            title: 'setting.items_declinations',
                            computedBreadcrumbs: (route) => {
                                return [
                                    {
                                        name: 'breadcrumbs.product.list',
                                        routeName: 'settings-items'
                                    },
                                    {
                                        name: route.query.product ? 'breadcrumbs.product.details' : 'breadcrumbs.product.create',
                                        routeName: 'settings-items'
                                    }
                                ]
                            },
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.product.list',
                                    routeName: 'settings-items-view'
                                }
                            ],
                            requiresPermission: 'product_view'
                        }
                    },
                    {
                        path: 'details',
                        component:  () => import('@/views/Items/Product/details.vue'),
                        name: 'items-details',
                        meta: {
                            title: 'setting.items_declinations',
                            computedBreadcrumbs: (route) => {
                                return [
                                    {
                                        name: 'breadcrumbs.product.list',
                                        routeName: 'settings-items-view'
                                    },
                                    {
                                        name: route.query.product ? 'breadcrumbs.product.details' : 'breadcrumbs.product.create',
                                        routeName: 'settings-items-view'
                                    }
                                ]
                            },
                            requiresPermission: 'product_view'
                        }
                    },
                    {
                        path: 'attributes',
                        component:  () => import('@/views/Items/Attribute/Index.vue'),
                        name: 'attributes',
                        meta: { requiresPermission: 'attribut_view' },
                    },
                    {
                        path: 'brands',
                        component:  () => import('@/views/Items/Brand/Index.vue'),
                        name: 'brands',
                        meta: { requiresPermission: 'marque_view' },
                    },
                    {
                        path: 'categories',
                        component:  () => import('@/views/Items/Category/Index.vue'),
                        name: 'categories',
                        meta: { requiresPermission: 'category_view' },
                    }
                ]
            },


            /*
             |--------------------------------------------------------------------------
             | Module Routes
             |--------------------------------------------------------------------------|
             */
            {
                path: 'modules',
                children: [
                    {
                        path: '',
                        component:  () => import('@/views/Module/Index.vue'),
                        name: 'modules',
                        meta: { requiresPermission: 'module_view' },
                    }
                ]
            },

            {
                path: 'settings',
                name: 'settings',
                component: () => import('../views/Settings/IndexView.vue'),
                meta: { requiresAuth: true },
            },
            {
                name: "settings-system-new",
                path: "settings-society",
                component: () => import('../views/Settings/Layout/BaseSettingProductLayout.vue'),
                children: [
                    {
                        name: "settings-company",
                        path: "company",
                        component: () => import('@/views/Settings/Company/Index.vue'),
                        meta: {
                            title: 'setting.companyInformation',
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-new'
                                }
                            ],
                            requiresPermission: 'general_information_view',
                        },
                    },
                    {
                        name: "settings-taxes",
                        path: "taxes",
                        component: () => import('@/views/Settings/Tax/IndexView'),
                        meta: {
                            title: 'setting.taxes',
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-new'
                                }
                            ],
                            requiresPermission: 'taxe_view',
                        },
                    },
                    {
                        name: "settings-smtp",
                        path: "smtp",
                        component: () => import('@/views/Settings/Smtp/index.vue'),
                        meta: {
                            title: 'setting.smtp',
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-new'
                                }
                            ],
                            requiresPermission: 'taxe_view',
                        },
                    },
                    {
                        name: "settings-departments",
                        path: "departments",
                        component: () => import('@/views/Settings/User/Department/IndexView'),
                        meta: {
                            title: 'setting.departments',
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-new'
                                }
                            ],
                            requiresPermission: 'department_view',
                        },
                    },
                    {
                        name: "settings-department-users",
                        path: "department/:id/users",
                        component: () => import('@/views/Settings/User/Department/UserView'),
                        meta: {
                            title: 'setting.departments.users',
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-new'
                                }
                            ],
                            requiresPermission: 'department_view',
                        },
                    }
                ]
            },
            {
                name: "settings-user",
                path: "settings-user",
                component: () => import('../views/Settings/Layout/BaseSettingUserLayout.vue'),
                children: [
                    {
                        name: "settings-users",
                        path: "users",
                        component: () => import('@/views/Settings/User/User.vue'),
                        meta: {
                            title: 'setting.users',
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-new'
                                }
                            ],
                            requiresPermission: 'users_view',
                        },
                    },
                    {
                        name: "settings-users-permission-view",
                        path: ':id/view',
                        component: () => import('../views/Settings/User/ViewUserPermission.vue'),
                        meta: {
                            title: "setting.users",
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-new'
                                }
                            ],
                            requiresPermission: 'users_permission'
                        }
                    },
                    {
                        name: "settings-rights",
                        path: "rights",
                        component: () => import('../views/Settings/Right/Right.vue'),
                        meta: {
                            title: 'setting.rights',
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-new'
                                }
                            ],
                            requiresPermission: 'right_view'
                        }
                    },
                    {
                        name: "settings-rights-view",
                        path: 'settings/rights/:id/view',
                        component: () => import('../views/Settings/Right/RightView.vue'),
                        meta: {
                            title: "setting.rights",
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-new'
                                }
                            ],
                            requiresPermission: 'right_permission_view'
                        }
                    }
                ]
            },
            {
                name: "settings-system",
                path: "settings-system",
                component: () => import('../views/Settings/Layout/BaseSettingSystemLayout.vue'),
                children: [
                    {
                        name: "settings-system-mail",
                        path: "system",
                        component: () => import('@/views/Settings/System/index.vue'),
                        meta: {
                            title: 'setting.systemInformation',
                            breadcrumbs: [
                                {
                                    name: 'breadcrumbs.setting.setting',
                                    routeName: 'settings-system-new'
                                }
                            ],
                        },
                    },

                ]
            },



            CrmRoutes

        ]
    },

    {
        path: '/admin/permission-denied/403',
        name: 'PermissionDenied',
        component: PermissionDeniedView,
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFoundPageView

    }




]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach(async (to, from, next) => {
    if(to.name !== from.name) {
        store.commit(UPDATE_APP_LOADING_STATUS, true)
        NProgress.start()
    }

    if (to.matched.some(m => m.meta.redirectIfTenant)) {
        if(isSubDomain()) {
            if (!store.getters['auth/isAuthenticated']) {
                return next('/login')
            }else {
                return next('/admin/home')
            }
        }
    }

    if (to.matched.some(m => m.meta.requiresTenant)) {
        if(!isSubDomain()) {
            return next('/create_account')
        }
    }

    if (to.matched.some(m => m.meta.requiresAuth)) {
        if (!store.getters['auth/isAuthenticated']) {
            return next('/login')
        }else{
            await axios.get(`/get/user`).then((response) => {
                if(response.status === 204){
                    store.dispatch('auth/logout', true)
                }
            })
        }
    }

    if (to.matched.some(m => m.meta.redirectIfAuthenticated) && store.getters['auth/isAuthenticated']) {
        return next('/admin/home')
    }


    let meta_permission = to.matched[to.matched.length - 1].meta?.requiresPermission

    if (meta_permission) {
        let hasAccess = false;
        await axios
            .get(`/permission/${meta_permission}`)
            .then(() => {
                hasAccess = true;
            })
            .catch(() => {
                hasAccess = false;
            });
        if (!hasAccess) {
            return next(`/admin/permission-denied/403`);
        }
    }

    return next()
})

router.afterEach(() => {
    NProgress.done()
    store.commit(UPDATE_APP_LOADING_STATUS, false)
})

export default router
