import * as types from './mutation-types'


export const loadCategories = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/categories/collection').then((response) => {
            commit(types.ADD_CATEGORIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProductsCategories = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/products/categories').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateCategoryStatus = ({commit}, category) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + category + '/category/status').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createCategory = ({commit}, data) => {

    let formData = new FormData();
    formData.append('name', data.name);
    formData.append('image', data.image);
    formData.append('online', data.online);
    formData.append('image', data.image);
    data.categories.forEach((category, key) => {
            formData.append('categories[' + key + ']', category);
        }
    )
    formData.append('_method', 'POST');

    return new Promise((resolve, reject) => {
        window.axios.post('store/category', formData, data, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
            commit(types.ADD_CATEGORY, response.data.category)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateCategory = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/category', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteCategory = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/category').then((response) => {
            commit(types.ADD_CATEGORIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setVisibleCategory = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('setVisible/category', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const uploadImage = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('category/upload-image', data).then((response) => {
            commit(types.SET_IMAGE, { id: response.data.id, image: response.data.image })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
