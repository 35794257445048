import * as types from './mutation-types'

export const loadSources = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/sources/collection', data).then((response) => {
            commit(types.ADD_SOURCES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateSource = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/source', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createSource = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/source', data).then((response) => {
            commit(types.ADD_SOURCE, response.data.source)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const deleteSource = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/source').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

