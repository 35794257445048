import * as types from './mutation-types'

export default {
  [types.GET_COMPANY_INFOS](state, data) {
    state.company = data
  },
  [types.GET_CURRENCIES](state, data) {
    state.currencies = data.currencies
  }
}
