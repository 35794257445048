<template>
 <div class="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column  bg-white"
    id="kt_create_account_stepper">
    <div class="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative" style="background-color: rgb(239, 251, 240); box-shadow: rgba(0, 0, 0, 0.15) 3px 0px 17px; height: 100vh; ">
      <div class="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">
        <div class="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20">
          <a href="#" class="mb-10 mb-lg-20">
            <img alt="Logo" src="assets/media/login.png" class="h-50px" />
          </a>
          <div class="stepper-nav">
            <div class="stepper-item current" data-kt-stepper-element="nav">
              <div class="stepper-line w-40px"></div>
              <div class="stepper-icon w-40px h-40px">
                <i class="stepper-check fas fa-check"></i>
                <span class="stepper-number">1</span>
              </div>
              <div class="stepper-label">
                <h3 class="stepper-title">{{ $t('auth.general_society') }}</h3>
                <div class="stepper-desc fw-bold">{{ $t('auth.general_society_details') }}</div>
              </div>
            </div>
            <div class="stepper-item" data-kt-stepper-element="nav">
              <div class="stepper-line w-40px"></div>
              <div class="stepper-icon w-40px h-40px">
                <i class="stepper-check fas fa-check"></i>
                <span class="stepper-number">2</span>
              </div>
              <div class="stepper-label">
                <h3 class="stepper-title">{{ $t('auth.address_society') }}</h3>
                <div class="stepper-desc fw-bold">{{ $t('auth.address_society_details') }}</div>
              </div>
            </div>
            <div class="stepper-item" data-kt-stepper-element="nav">
              <div class="stepper-line w-40px"></div>
              <div class="stepper-icon w-40px h-40px">
                <i class="stepper-check fas fa-check"></i>
                <span class="stepper-number">3</span>
              </div>
              <div class="stepper-label">
                <h3 class="stepper-title">{{ $t('auth.creation_admin') }}</h3>
                <div class="stepper-desc fw-bold">{{ $t('auth.creation_admin_details') }}</div>
              </div>
            </div>
            <div class="stepper-item" data-kt-stepper-element="nav">
              <div class="stepper-line w-40px"></div>
              <div class="stepper-icon w-40px h-40px">
                <i class="stepper-check fas fa-check"></i>
                <span class="stepper-number">4</span>
              </div>
              <div class="stepper-label">
                <h3 class="stepper-title">{{ $t('auth.checking') }}</h3>
                <div class="stepper-desc fw-bold">{{ $t('auth.check_data') }}</div>
              </div>
            </div>
            <div class="stepper-item" data-kt-stepper-element="nav">
              <div class="stepper-line w-40px"></div>
              <div class="stepper-icon w-40px h-40px">
                <i class="stepper-check fas fa-check"></i>
                <span class="stepper-number">5</span>
              </div>
              <div class="stepper-label">
                <h3 class="stepper-title">{{ $t('auth.end') }}</h3>
                <div class="stepper-desc fw-bold">{{ $t('auth.congratulate') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row-fluid py-10 " style="height: 100vh!important">
      <div class="d-flex align-items-center ms-auto me-1 me-lg-3">
        <LangDropdown/>
      </div>
      <div class="d-flex flex-center flex-column flex-column-fluid">
        <div class="w-lg-700px p-10 p-lg-15 mx-auto">
          <form class="my-auto pb-5" novalidate="novalidate" id="kt_create_account_form">
            <div class="current" data-kt-stepper-element="content">
              <div style="height : 64vh !important;" class="w-100 scroll-y me-n7 pe-7">
                <div class="pb-10 pb-lg-15">
                  <h2 class="fw-bolder d-flex align-items-center text-dark">{{ $t('auth.general_society') }}
                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                      :title="$t('auth.general_society_details')"></i>
                  </h2>
                  <div class="text-muted fw-bold fs-6">{{ $t('auth.general_society_details') }}</div>
                  <RequiredText/>
                </div>
                <div class="fv-row mb-5">
                  <label class="form-label mb-3 required">{{ $t('auth.name_society') }}</label>
                  <input v-model="form.society_name" type="text" class="form-control form-control-lg form-control-solid"
                    ref="society_name" name="society_name" />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="fv-row mb-5">
                      <label class="fs-6 fw-bold form-label required">{{ $t('auth.matricule_number') }}</label>
                      <input v-model="form.matricule_number" name="matricule_number"
                        class="form-control form-control-lg form-control-solid" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="fv-row mb-5">
                      <label class="form-label mb-3 required">{{ $t('auth.rccm_number') }}</label>
                      <input v-model="form.rccm_number" type="text"
                        class="form-control form-control-lg form-control-solid" name="rccm_number" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-5">
                    <label class="fs-6 fw-bold form-label">{{ $t('auth.tva_number') }}</label>
                    <input v-model="form.tva_number" name="tva_number"
                      class="form-control form-control-lg form-control-solid" />
                  </div>
                  <div class="col-md-6 mb-5">
                    <label class="fs-6 fw-bold form-label ">{{ $t('auth.website') }}</label>
                    <input v-model="form.website" name="website"
                      class="form-control form-control-lg form-control-solid" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-5">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_gender') }}</label>
                      <select name="responsable_gender" v-model="form.responsable_gender"
                        class="form-select form-select-lg form-select-solid " data-control="select2"
                        :data-placeholder="$t('auth.responsable_gender')" data-allow-clear="true">
                        <option></option>
                        <option v-for="gender in getGenders" :value="gender.value">{{ gender.fr }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-5">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_lastname') }}</label>
                      <input v-model="form.responsable_lastname" name="responsable_lastname"
                             class="form-control form-control-lg form-control-solid" />
                    </div>
                  </div>
                  <div class="col-md-6 mb-5">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_firstname') }}</label>
                      <input v-model="form.responsable_firstname" name="responsable_firstname"
                             class="form-control form-control-lg form-control-solid" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 ">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_email') }}</label>
                      <input v-model="form.responsable_email" type="email" name="responsable_email"
                        class="form-control form-control-lg form-control-solid" />
                    </div>
                  </div>
                  <div class="col-md-6 ">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_phone') }}</label>
                      <vue-tel-input @country-changed="secondInputCountryChanged" :inputOptions="options"
                        @input="secondInputChanged" :autoDefaultCountry="true" id="responsable_phone"
                        class="form-control form-control-solid" name="responsable_phone"
                        v-model="form.responsable_phone"></vue-tel-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" data-kt-stepper-element="content">
              <div style="height : 64vh !important;" class="w-100 scroll-y me-n7 pe-7">
                <div class="w-100">
                  <div class="pb-10 pb-lg-15">
                    <h2 class="fw-bolder d-flex align-items-center text-dark">{{ $t('auth.address_society') }}
                    </h2>
                    <div class="text-muted fw-bold fs-6">{{ $t('auth.address_society_details') }}</div>
                    <RequiredText/>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-5">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label required">{{ $t('auth.society_email') }}</label>
                        <input v-model="form.society_email" name="society_email"
                          class="form-control form-control-lg form-control-solid" />
                      </div>
                    </div>
                    <div class="col-md-6 mb-5">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label required">{{ $t('auth.society_phone') }}</label>
                        <vue-tel-input @country-changed="inputCountryChanged" :inputOptions="options"
                          @input="inputChanged" :autoDefaultCountry="true" id="society_phone"
                          class="form-control form-control-solid" name="society_phone"
                          v-model="form.society_phone"></vue-tel-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-5">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label required">{{ $t('auth.currency') }}</label>
                        <select name="currency" v-model="form.currency_id"
                                class="form-select form-select-lg form-select-solid " data-control="select2"
                                :data-placeholder="$t('auth.currency_placeholder')">
                          <option></option>
                          <option v-for="currency in getCurrencies" :key="currency.id" :value="currency.id">{{ currency.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-md-6 mb-5">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label required">{{ $t('auth.country') }}</label>
                        <select name="country" v-model="form.country"
                          class="form-select form-select-lg form-select-solid " data-control="select2"
                          data-placeholder="Selectionner un pays" data-allow-clear="true">
                          <option></option>
                          <option v-for="country in getCountries" :key="country.id" :value="country.id">{{ country.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 mb-5">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label required">{{ $t('auth.city') }}</label>
                        <input v-model="form.city" name="city" class="form-control form-control-lg form-control-solid" />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-5">
                    <div class="col-md-8">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label required">{{ $t('auth.address') }}</label>
                        <input v-model="form.address" name="address"
                          class="form-control form-control-lg form-control-solid" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label">{{ $t('auth.zipcode') }}</label>
                        <input v-model="form.zipcode" name="zipcode"
                          class="form-control form-control-lg form-control-solid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" data-kt-stepper-element="content">
              <div style="height : 64vh !important;" class="w-100 scroll-y me-n7 pe-7">
                <div class="w-100">
                  <div class="pb-10 pb-lg-15">
                    <h2 class="fw-bolder d-flex align-items-center text-dark">{{ $t('auth.creation_admin') }}
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                        :title="$t('auth.creation_admin_details')"></i>
                    </h2>
                    <div class="text-muted fw-bold fs-6">{{ $t('auth.creation_admin_details') }}</div>
                    <RequiredText/>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mb-5">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label required">{{ $t('auth.gender') }}</label>
                        <select name="gender" v-model="form.gender"
                          class="form-select kt_select2 form-select-lg form-select-solid" data-control="select2"
                          data-placeholder="Genre" data-allow-clear="true">
                          <option></option>
                          <option v-for="gender in getGenders" :value="gender.value">{{ gender.fr }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-5">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label required">{{ $t('auth.lastname') }}</label>
                        <input v-model="form.lastname" name="admin_lastname"
                               class="form-control form-control-lg form-control-solid" />
                      </div>
                    </div>
                    <div class="col-md-6 mb-5">
                      <div class="fv-row">
                        <label class="fs-6 fw-bold form-label required">{{ $t('auth.firstname') }}</label>
                        <input v-model="form.firstname" name="admin_firstname"
                               class="form-control form-control-lg form-control-solid" />
                      </div>
                    </div>
                  </div>
                  <div class="fw-row mb-5">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold form-label required">{{ $t('auth.email') }}</label>
                      <input v-model="form.email" name="admin_email"
                        class="form-control form-control-lg form-control-solid" />
                    </div>
                  </div>
                  <div class="fw-row">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold form-label required">{{ $t('auth.responsable_phone') }}</label>
                      <vue-tel-input @country-changed="thirstInputCountryChanged" :inputOptions="options"
                        @input="thirstInputChanged" :autoDefaultCountry="true" id="admin_phone"
                        class="form-control form-control-solid" name="admin_phone" v-model="form.phone"></vue-tel-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" data-kt-stepper-element="content">
              <div style="height : 64vh !important;" class="w-100 scroll-y me-n7 pe-7">
                <div class="w-100">
                  <div class="pb-8 pb-lg-10">
                    <h2 class="fw-bolder text-dark">{{ $t('auth.checking') }}</h2>
                    <div class="text-muted fw-bold fs-6">{{ $t('auth.check_data') }}</div>
                  </div>
                  <div class="mb-0">
                    <div class="row mb-10">
                      <div class="col-md-6">
                        <h4 class="mb-5">{{ $t('auth.general_society') }}</h4>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.name_society') }} : </span>
                          <span class="text-gray-600"> {{ form.society_name }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.matricule_number') }} : </span>
                          <span class="text-gray-600"> {{ form.matricule_number }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.rccm_number') }} : </span>
                          <span class="text-gray-600"> {{ form.rccm_number }}</span>
                        </div>
                        <div class="fw-row mb-2 ">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.tva_number') }} : </span>
                          <span class="text-gray-600"> {{ form.tva_number }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold"> {{ $t('auth.website') }} : </span>
                          <span class="text-gray-600"> {{ form.website }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.responsable_gender') }} : </span>
                          <span class="text-gray-600"> {{ form.responsable_gender === 'male' ? 'male' : 'female' }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.responsable_lastname') }} : </span>
                          <span class="text-gray-600"> {{ form.responsable_lastname }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.responsable_firstname') }} : </span>
                          <span class="text-gray-600"> {{ form.responsable_firstname }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.responsable_email') }} : </span>
                          <span class="text-gray-600"> {{ form.responsable_email }}</span>
                        </div>
                        <div class="fw-row">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.responsable_phone') }} : </span>
                          <span class="text-gray-600"> {{ form.responsable_phone }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <h4 class="mb-5">{{ $t('auth.address_society') }}</h4>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.society_email') }} : </span>
                          <span class="text-gray-600"> {{ form.society_email }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.society_phone') }} : </span>
                          <span class="text-gray-600"> {{ form.society_phone }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.country') }} : </span>
                          <span class="text-gray-600"> {{ getCountries.find(x => x.id === form.country) ?
                            getCountries.find(x => x.id === form.country).name : "" }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.city') }} : </span>
                          <span class="text-gray-600"> {{ form.city }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.address') }} : </span>
                          <span class="text-gray-600"> {{ form.address }}</span>
                        </div>
                        <div class="fw-row mb-10">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.zipcode') }} : </span>
                          <span class="text-gray-600"> {{ form.zipcode }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.currency') }} : </span>
                          <span class="text-gray-600"> {{ getCurrencies.find(x => x.id === form.currency_id) ?
                              getCurrencies.find(x => x.id === form.currency_id).name : "" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h4 class="mb-5">{{ $t('auth.creation_admin') }}</h4>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.gender') }} : </span>
                          <span class="text-gray-600"> {{ form.gender === 'male' ? 'Homme' : 'Femme' }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.lastname') }} : </span>
                          <span class="text-gray-600"> {{ form.lastname }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.firstname') }} : </span>
                          <span class="text-gray-600"> {{ form.firstname }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2 "> {{ $t('auth.email') }} : </span>
                          <span class="text-gray-600"> {{ form.email }}</span>
                        </div>
                        <div class="fw-row mb-2">
                          <span class="fs-5 fw-bold mr-2"> {{ $t('auth.phone') }} : </span>
                          <span class="text-gray-600"> {{ form.phone }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" data-kt-stepper-element="content">
              <div style="height : 64vh !important;" class="w-100 scroll-y me-n7 pe-7">
                <div class="w-100">
                  <div class="pb-8 pb-lg-10">
                    <h2 class="fw-bolder text-dark">{{ $t('auth.end') }}</h2>
                    <div class="text-muted fw-bold fs-6">{{ $t('auth.congratulate') }}
                    </div>
                  </div>
                  <div class="mb-0">
                    <div class="notice d-flex bg-light-success rounded border-success border border-dashed p-6">
                      <span class="svg-icon svg-icon-2tx svg-icon-success me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                          <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)"
                            fill="currentColor" />
                          <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <div class="d-flex flex-stack flex-grow-1">
                        <div class="fw-bold">
                          <h4 class="text-gray-900 fw-bolder">{{ $t('inscription_welcome') }}</h4>
                          <div class="fs-6 text-gray-700">
                            {{ $t('inscription_check') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-stack pt-15">
              <div class="mr-2">
                <button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
                  <span class="svg-icon svg-icon-4 me-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                      <path
                        d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                        fill="currentColor" />
                    </svg>
                  </span>
                  {{ $t('previous') }}
                </button>
              </div>
              <div>
                <button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="submit">
                  <span class="indicator-label">{{ $t('send') }}
                    <span class="svg-icon svg-icon-4 ms-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                          fill="currentColor" />
                        <path
                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                          fill="currentColor" />
                      </svg>
                    </span>
                  </span>
                  <span class="indicator-progress">{{ $t('loading') }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
                <button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next">Continue
                  <span class="svg-icon svg-icon-4 ms-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                        fill="currentColor" />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="currentColor" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="d-flex flex-center fs-6">version {{ versionApp }}</div>
      <div class="d-flex flex-center flex-wrap fs-6 p-1 pb-0">
        <div class="d-flex flex-center fw-bold fs-6">
          <span   class="text-muted text-hover-primary px-2">{{ $t('powered') }}
            <a target="_blank" href="https://netbyus.com">{{ $t('poweredby') }}</a>
          </span>
          <span class="text-muted text-hover-primary px-2" >©{{ new Date().getFullYear() }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>



import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css';
import AttachFile from '@/components/AttachFile.vue'
import { mapActions } from "vuex";
import { appVersion } from "@/mixins/Helpers";
import LangDropdown from "@/components/LangDropdown";
import RequiredText from "@/components/RequiredText";

export default {
  name: "CreateAccount",
  data() {
    return {
      backUrl: process.env.VUE_APP_PORT ? process.env.VUE_APP_HOST + ':' + process.env.VUE_APP_PORT : process.env.VUE_APP_HOST,
      form: {
        society_name: "",
        society_email: "",
        society_phone: "",
        society_phone_code: "",
        responsable_phone_code: "",
        ifu_file: [],
        rccm_file: [],
        address_file: [],
        name_society: "",
        matricule_number: "",
        rccm_number: "",
        responsable_gender: "",
        responsable_lastname: "",
        responsable_firstname: "",
        responsable_email: "",
        responsable_phone: "",
        address: "",
        city: null,
        zipcode: "",
        tva_number: "",
        website: "",
        country: "",
        currency_id: "",
        gender: "",
        lastname: "",
        firstname: "",
        phone: "",
        phone_code: "",
        condition: true
      },
      getGenders:[],
      getCountries: [],
      getCurrencies: [],
      dataInvoice: {
        ifu: null,
        tokenApi: null,
        defaultTaxe: null
      },
      isBeninCountry: false,
      options: {
        placeholder: "Ex : 97 97 00 00",
        showDialCode: true
      },
      langs: {
        en: {
          'img': '/assets/media/united-states.svg',
          'title': 'header.language.english',
          'code': 'en'
        },
        fr: {
          'img': '/assets/media/france.svg',
          'title': 'header.language.french',
          'code': 'fr'
        },
      }
    }

  },
  components: {
    RequiredText,
    LangDropdown,
    VueTelInput,
    AttachFile
  },

  computed: {
    currentLanguage() {
      return this.langs[this.$i18n.locale]
    },
    versionApp() {
      return appVersion.value
    }
  },
  async mounted() {

    this.initSelect2()
    await this.loadCountries()
    await this.loadCurrencies()
    await this.loadGenders()
    this.validateCreateAccountForm()
  },
  methods: {

    ...mapActions('auth', [
      'createAccount',
    ]),



    changeLang(lang) {
      this.$i18n.locale = lang.code
      localStorage.setItem('locale', lang.code)
    },

    async loadCountries() {
      window.axiosDefault.get('get/countries').then((response) => {
        this.getCountries = response.data.countries
      }).catch((err) => {

      })
    },

    async loadCurrencies() {
      window.axiosDefault.get('get/currencies/collection').then((response) => {
        this.getCurrencies = response.data.currencies
      }).catch((err) => {

      })
    },

    async loadGenders() {
      window.axiosDefault.get('get/gender/enum').then((response) => {
        this.getGenders = response.data
      }).catch((err) => {

      })
    },

    validateCreateAccountForm() {
      var e, t, i, o, s, r, a = [];
      (e = document.querySelector("#kt_modal_create_account")) && new bootstrap.Modal(e), t = document.querySelector("#kt_create_account_stepper"), i = t.querySelector("#kt_create_account_form"), o = t.querySelector('[data-kt-stepper-action="submit"]'), s = t.querySelector('[data-kt-stepper-action="next"]'), (r = new KTStepper(t)).on("kt.stepper.changed", ((e) => {
        4 === r.getCurrentStepIndex() ? (o.classList.remove("d-none"), o.classList.add("d-inline-block"), s.classList.add("d-none")) : 5 === r.getCurrentStepIndex() ? (o.classList.add("d-none"), s.classList.add("d-none")) : (o.classList.remove("d-inline-block"), o.classList.remove("d-none"), s.classList.remove("d-none"))
      })), r.on("kt.stepper.next", ((e) => {
        var t = a[e.getCurrentStepIndex() - 1];
        t ? t.validate().then(((t) => {
          "Valid" == t ? (e.goNext(), KTUtil.scrollTop()) : Swal.fire({
            text: i18n.global.t('formValidation.error_alert'),
            icon: "error",
            buttonsStyling: !1,
            confirmButtonText: "Ok!",
            customClass: { confirmButton: "btn btn-light" },
            confirmButtonColor: '#0072CE',
            cancelButtonColor: '#d33',

          }).then((() => {
            KTUtil.scrollTop()
          }))
        })) : (e.goNext(), KTUtil.scrollTop())
      })), r.on("kt.stepper.previous", ((e) => {
        e.goPrevious(), KTUtil.scrollTop()
      })),
        a.push(FormValidation.formValidation(i, {
          fields: {
            society_name: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                }
              }
            },
            matricule_number: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                }
              }
            },
            rccm_number: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                }
              }
            },
            responsable_gender: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                }
              }
            },
            responsable_lastname: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                }
              }
            },
            responsable_firstname: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                }
              }
            },
            responsable_email: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                },
                emailAddress: {
                  message: i18n.global.t('formValidation.field_must_be_email')
                }
              }
            },
            responsable_phone: {
              validators: {
                callback: {
                  message: i18n.global.t('formValidation.phone_number_not_valid'),
                  callback: (value, validator, $field) => {
                    let number = this.form.responsable_phone.replace(this.form.responsable_phone_code, '')
                    return number.trim().length ? true : false
                  }
                },
              }
            }
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger,
            bootstrap: new FormValidation.plugins.Bootstrap5({
              rowSelector: ".fv-row",
              eleInvalidClass: "",
              eleValidClass: ""
            }),
          }
        })),
        a.push(FormValidation.formValidation(i, {
          fields: {
            society_email: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                },
                emailAddress: {
                  message: i18n.global.t('formValidation.field_must_be_email')
                }
              }
            },
            society_phone: {
              validators: {
                callback: {
                  message: i18n.global.t('formValidation.phone_number_not_valid'),
                  callback: (value, validator, $field) => {
                    let number = this.form.society_phone.replace(this.form.society_phone_code, '')
                    return number.trim().length ? true : false
                  }
                },
              }
            },
            country: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                },
              }
            },
            city: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                },
              }
            },
            currency_id: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                },
              }
            },
            address: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                },
              }
            },
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger,
            bootstrap: new FormValidation.plugins.Bootstrap5({
              rowSelector: ".fv-row",
              eleInvalidClass: "",
              eleValidClass: ""
            })
          }
        })),
        a.push(FormValidation.formValidation(i, {
          fields: {
            gender: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                }
              }
            },
            admin_lastname: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                }
              }
            },
            admin_firstname: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                }
              }
            },
            admin_email: {
              validators: {
                notEmpty: {
                  message: i18n.global.t('formValidation.field_required')
                },
                emailAddress: {
                  message: i18n.global.t('formValidation.field_must_be_email')
                }
              }
            },
            admin_phone: {
              validators: {
                callback: {
                  message: i18n.global.t('formValidation.phone_number_not_valid'),
                  callback: (value, validator, $field) => {
                    let number = this.form.phone.replace(this.form.phone_code, '')
                    return number.trim().length ? true : false
                  }
                },
              }
            },
          },
          plugins: {
            trigger: new FormValidation.plugins.Trigger,
            bootstrap: new FormValidation.plugins.Bootstrap5({
              rowSelector: ".fv-row",
              eleInvalidClass: "",
              eleValidClass: ""
            })
          }
        })),
        o.addEventListener("click", ((e) => {
          a[2].validate().then((async (t) => {
            if ("Valid" == t) {
              e.preventDefault()

              if (!this.form.condition) {
                window.toastr.error("Veuillez accepter les conditions générales d'utilisation")
              } else {
                o.disabled = !0
                this.createAccount(this.form).then((res) => {

                  window.toastr.success(res.data.message)
                  o.removeAttribute("data-kt-indicator")
                  o.disabled = !1
                  r.goNext()
                  //this.$router.push('/login')

                }).catch(() => {
                  o.removeAttribute("data-kt-indicator")
                  o.disabled = !1
                  KTUtil.scrollTop()
                })
              }

            } else {
              Swal.fire({
                text: "Sorry, looks like there are some errors detected, please try again.",
                icon: "error",
                buttonsStyling: !1,
                confirmButtonText: "Ok, got it!",
                customClass: { confirmButton: "btn ", cancelButton: "btn text-white" },
                confirmButtonColor: '#0072CE',
                cancelButtonColor: '#d33',
              }).then((() => {
                KTUtil.scrollTop()
              }))
            }

          }))
        }))
    },

    inputChanged(number, phoneObject) {
      let errorDiv = document.querySelector('[data-field="responsable_phone"][data-field="responsable_phone"][data-validator="callback"]')
      if (errorDiv) {
        errorDiv.remove()
      }

      if (phoneObject) {
        this.form.society_phone = phoneObject.nationalNumber
        this.form.society_phone_code = '+' + phoneObject.countryCallingCode
      }
    },

    secondInputChanged(number, phoneObject) {
      let errorDiv = document.querySelector('[data-field="society_phone"][data-field="society_phone"][data-validator="callback"]')
      if (errorDiv) {
        errorDiv.remove()
      }
      if (phoneObject) {
        this.form.responsable_phone = phoneObject.nationalNumber
        this.form.responsable_phone_code = '+' + phoneObject.countryCallingCode
      }
    },

    inputCountryChanged(country) {
      this.form.society_phone_code = '+' + country.dialCode
    },

    secondInputCountryChanged(country) {
      this.form.responsable_phone_code = '+' + country.dialCode
    },

    thirstInputCountryChanged(country) {
      this.form.phone_code = '+' + country.dialCode
    },

    thirstInputChanged(number, phoneObject) {
      let errorDiv = document.querySelector('[data-field="admin_phone"][data-field="admin_phone"][data-validator="callback"]')
      if (errorDiv) {
        errorDiv.remove()
      }

      if (phoneObject) {
        this.form.phone = phoneObject.nationalNumber
        this.form.phone_code = '+' + phoneObject.countryCallingCode
      }
    },

    initSelect2() {
      $("select[data-control='select2']").select2()
      $("select[data-control='select2']").change((e) => {
        switch (e.target.name) {
          case 'city':
            this.form.city = e.target.value
            break
          case 'country':
            this.form.country = e.target.value
            const countryTrouve = this.getCountries.find(country => country.id === e.target.value)
            this.isBeninCountry = countryTrouve?.iso_alpha2 == 'BJ' ? true : false
            break
          case 'currency':
            this.form.currency_id = e.target.value
            break
          case 'gender':
            this.form.gender = e.target.value
            break
          case 'type_taxe':
            this.dataInvoice.defaultTaxe = e.target.value
            break
          case 'responsable_gender':
            this.form.responsable_gender = e.target.value
            break

        }
      })
    },

  }

}
</script>

<style scoped>@media (max-width: 991px) {
  .step-none {
    display: none !important;
  }
}</style>
