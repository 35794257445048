import * as types from './mutation-types'

export default {
  [types.LOAD_OPPORTUNITY_ACTIVITIES](state, activities) {
    state.opportunityActivities = activities.data
  },
  [types.LOAD_EMAIL_ACTIVITY_TYPE_ENUM](state, data) {
    state.emailActivityTypeEnum = data
  },
  [types.LOAD_CALL_ACTIVITY_TYPE_ENUM](state, data) {
    state.callActivityTypeEnum = data
  },
  [types.LOAD_ACTIVITY_TYPES](state, activityTypes) {
    state.activityTypes = activityTypes.data
  },
}
