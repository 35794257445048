import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const initialState = {
    taxes: [],
    products: [],
    storeProductSaleElements: {},
    product_sale_elements: [],
    product_sale_elements_by_categories: [],
    lots: [],
    types: []
}

export default {
    namespaced: true,

    state: initialState,

    getters: getters,

    actions: actions,

    mutations: mutations
}
