import * as types from './mutation-types'

export const loadTags = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/tags/collection', data).then((response) => {
            commit(types.ADD_TAGS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateTag = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/tag', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createTag = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/tag', data).then((response) => {
            commit(types.ADD_TAG, response.data.tag)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const deleteTag = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/tag').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

