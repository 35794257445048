<template>
  <div class="container" style="height: 80vh !important;">
    <div class="content">
      <span class=" icon"> 403</span>
      <h1>{{$t('permission_denied')}}</h1>
      <p>{{$t('permission_denied_text')}}</p>
      <button class="btn btn-success btn-sm" @click="() => $router.go(-1)">{{$t('back')}}</button>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.content {
  text-align: center;
}

.icon {
  font-size: 50px; /* Ajustez la taille de police selon vos besoins */
  margin-bottom: 20px;
}
</style>
