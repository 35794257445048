import * as types from './mutation-types'

export const loadCities = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`get/cities/collection`).then((response) => {
      commit(types.ADD_CITIES, response.data.city)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateCity = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.patch('update/' + data.id + '/city', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}


export const createCity = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('store/city', data).then((response) => {
      commit(types.ADD_CITY, response.data.city)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const storeCity = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('store/' + data.id + '/city/value', data).then((response) => {
      let cities = response.data.countries || []
      cities.forEach((city) => {
        commit(types.ADD_CITY, city)
      })
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteCity = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.delete('delete/' + id + '/city').then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

