
<template>
  <div class="d-flex flex-center flex-column flex-column-fluid">
    <div class="w-lg-500px p-10 p-lg-15 mx-auto">
      <form class="form w-100" id="kt_new_password_form">
        <div class="text-center mb-10">
          <h1 class="text-dark mb-3 ">{{ $t('auth.new_password') }}</h1>
          <div class=" fw-bold fs-4 text-color">{{ $t('auth.new_password_cf') }}</div>
        </div>
        <div class="mb-10 fv-row" data-kt-password-meter="true">
          <div class="mb-1">
            <label class="form-label fw-bolder  fs-6 text-color">{{ $t('auth.new_password') }}</label>
            <div class="position-relative mb-3">
              <input v-model="form.password" class="form-control form-control-lg form-control-solid" type="password" placeholder="" name="password" autocomplete="off" />
              <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                <i class="bi bi-eye-slash fs-2"></i>
                <i class="bi bi-eye fs-2 d-none"></i>
              </span>
            </div>
            <div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
          </div>
          <div class="text-muted">{{ $t('auth.password_conditions')}}.</div>
        </div>
        <div class="fv-row mb-10">
          <label class="form-label fw-bolder  fs-6 text-color required">{{ $t('auth.cf_password') }}</label>
          <input v-model="form.password_confirmation" class="form-control form-control-lg form-control-solid" type="password" placeholder="" name="confirm-password" autocomplete="off" />
        </div>
        <div class="text-center">
          <button type="submit" id="kt_new_password_submit" class="btn btn-lg btn-primary fw-bolder">
            <span class="indicator-label">{{ $t('send') }}</span>
            <span class="indicator-progress">{{  $t('loading') }}<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: 'CreatePasswordView',
  data(){
    return {
      form : {
        password : '',
        password_confirmation : '',
        token: this.$route.params.token,
        id: this.$route.params.id,
      },
      user: {},
      langs: {
        en : {
          'img': '/assets/media/united-states.svg',
          'title': 'header.language.english',
          'code': 'en'
        },
        fr : {
          'img': '/assets/media/france.svg',
          'title': 'header.language.french',
          'code': 'fr'
        },
      }
    }
  },
  computed : {
    currentLanguage(){
      return this.langs[this.$i18n.locale]
    },
  },
  async mounted(){
    this.passwordChange()
  },

  methods: {
    ...mapActions('auth', [
      'resetPassword'
    ]),
    changeLang(lang){
      this.$i18n.locale = lang.code
      localStorage.setItem('locale', lang.code)
    },
    passwordChange() {
      var e, t, r, o, s = () => {};

      e = document.querySelector("#kt_new_password_form"), t = document.querySelector("#kt_new_password_submit"), o = KTPasswordMeter.getInstance(e.querySelector('[data-kt-password-meter="true"]')), r = FormValidation.formValidation(e, {
        fields: {
          password: {
            validators: {
              notEmpty: {
                message: "The password is required"
              },
              callback: {
                message: "Please enter valid password",
                callback: function(e) {
                  if (e.value.length > 0) return s()
                }
              }
            }
          },
          "confirm-password": {
            validators: {
              notEmpty: {
                message: "The password confirmation is required"
              },
              identical: {
                compare: function() {
                  return e.querySelector('[name="password"]').value
                },
                message: "The password and its confirm are not the same"
              }
            }
          },
          toc: {
            validators: {
              notEmpty: {
                message: "You must accept the terms and conditions"
              }
            }
          }
        },
        plugins: {
          trigger: new FormValidation.plugins.Trigger({
            event: {
              password: !1
            }
          }),
          bootstrap: new FormValidation.plugins.Bootstrap5({
            rowSelector: ".fv-row",
            eleInvalidClass: "",
            eleValidClass: ""
          })
        }
      }), t.addEventListener("click", ((s) => {
        this.$loading('kt_new_password_submit')
        s.preventDefault(),
            r.revalidateField("password"),
            r.validate().then(( async (r) => {
              if ("Valid" == r){
                let data = {
                  email :  this.user ? this.user.email : '',
                  token : this.form.token,
                  password : this.form.password,
                  password_confirmation : this.form.password_confirmation
                }
                await this.resetPassword(data).then((res) => {
                  this.$stopLoading('kt_new_password_submit')
                  window.toastr.success(res.data.message)
                  this.$router.push('/login')
                }).catch(() => {
                  this.$stopLoading('kt_new_password_submit')
                })
              }else {
                this.$stopLoading('kt_new_password_submit')
                Swal.fire({
                  text: "Sorry, looks like there are some errors detected, please try again.",
                  icon: "error",
                  buttonsStyling: !1,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary"
                  }
                })
              }
            }))
      })), e.querySelector('input[name="password"]').addEventListener("input", (function() {
        this.value.length > 0 && r.updateFieldStatus("password", "NotValidated")
      }))
    }
  },

  updated() {
    this.$nextTick(() => {
      KTApp.init();
    })
  }
}
</script>

<style scoped>
.text-color{
  color: rgba(16, 16, 16, 0.50);
}
</style>
