<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="toolbar" id="kt_toolbar">
     <AppSubHeader
          :title="title"
          :breadcrumbs="breadcrumbs"
          :back="true"
          :get-contacts="getContacts ?? []"
          @init-select="initSelect2"
      >

      </AppSubHeader>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container" class="container" style="max-width: 1550px">
        <div class="row">
          <div class="col-12 col-md-3 col-lg-3 mb-10">
            <div  class="card  mb-5 mb-xl-8">
              <div class="card-body pt-15">
                <div class="d-flex flex-center flex-column mb-5">
                  <div class="symbol symbol-150px symbol-circle mb-7">
                    <img :src="contact.avatar_url" alt="image" />
                    <div class="icon-overlay" v-if="contact.contactType == 'particular'" style="background-color: #F07817">
                        <i class="fas fa-user" style="color: white;"></i>
                    </div>
                    <div v-if="contact.contactType == 'society'" class="icon-overlay" style="background-color: #F3DC1F">
                        <i class="fas fa-building" style="color: #000;"></i>
                    </div>
                  </div>
                  <span class="fs-3 text-gray-800 text-center fw-bolder mb-5">
                    {{ contact?.fullname }}</span>
                    <div class="d-flex">
                      <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_user">
                        <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
                          <i class="fas fa-user"></i>
                        </span>
                      </div>
                      <div class="bg-gray-100 rounded min-w-150px py-3 px-3 mx-2 mb-3 phone-value">
                        <div class="fw-bold text-gray-900" v-if="contact.customer || contact.supplier">
                          <span v-if="contact.customer && contact.supplier">
                            {{ $t('contacts.customer') }} & {{ $t('contacts.supplier') }}
                          </span>
                          <span v-else-if="contact.customer"> {{ $t('contacts.customer') }}</span>
                          <span v-else-if="contact.supplier"> {{$t('contacts.supplier') }} </span>
                        </div>
                        <div class="fw-bold text-gray-900" v-else>
                          <span>--------------</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_email">
                        <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
                          <i class="fas fa-envelope"></i>
                        </span>
                      </div>
                      <div class="bg-gray-100 rounded min-w-150px py-3 px-3 mx-2 mb-3 phone-value">
                        <div class="fw-bold text-gray-900" v-if="contact.email">
                          {{ contact.email }}
                        </div>
                        <div class="fw-bold text-gray-900" v-else>
                          <span>--------------</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_phone">
                        <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
                          <i class="fas fa-phone-alt"></i>
                        </span>
                      </div>
                      <div class="bg-gray-100 rounded min-w-150px py-3 px-3 mx-2 mb-3 phone-value">
                        <div class="fw-bold text-gray-900" v-if="contact.phone && JSON.parse(contact.phone)[0]">
                          {{ contact && contact.phone && JSON.parse(contact.phone)[0] ?
                            JSON.parse(contact.phone)[0].toString() : "" }}
                        </div>
                        <div class="fw-bold text-gray-900" v-else>
                          <span>--------------</span>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="card card-body pt-6 mb-5">
              <h6 class="mb-2">{{$t('contacts.turnover')}}</h6>
              <h2 class="text-primary">{{formattedLocalAmount(contactStats.invoiceTurnover)}} {{currency?.symbol}}</h2>
            </div>
          </div>

          <div class="col-12 col-md-9 col-lg-9">
            <div class="card pb-0 px-5 mb-8 pt-20">
              <div class="d-flex flex-column flex-md-row justify-content-between">
                <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-3 mb-md-0">
                  <li class="nav-item" @click="openStat">
                    <a class="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_ecommerce_customer_stat">{{ $t('invoices.invoice') }}</a>
                  </li>
                  <li class="nav-item" @click="openOtherStat">
                    <a class="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_customer_edit">{{ $t('contacts.fiche_contact') }}</a>
                  </li>
                  <li class="nav-item" @click="openOtherStat">
                    <a class="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_customer_address">{{ $t('contacts.address') }}</a>
                  </li>
                  <li class="nav-item" @click="openStat">
                    <a class="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_estimate">{{ $t('devis.devis') }}</a>
                  </li>
                  <li class="nav-item" @click="openStat">
                    <a class="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_pipeline">{{ $t('crm.pipeline.opportunities') }}</a>
                  </li>
                </ul>
                
                <div v-show="showStat" class="text-md-end mb-2">
                  <DateRangePicker id="invoices-daterangepicker" @update:date="handleRangeDate" />
                </div>
              </div>
            </div>

            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show" id="kt_ecommerce_customer_edit" role="tabpanel">
                <div class="card  pt-4 mb-6 mb-xl-9">
                  <form id="kt_ecommerce_settings_general_form" @submit.prevent="updateContactData" class="form" action="#">

                    <div class="card-header border-0">
                      <div class="card-title">
                        <h2 class="fw-bolder mb-0">{{ $t('contacts.fiche_contact') }}</h2>
                      </div>
                      <div class="card-toolbar">
                        <Button btn-class="btn-sm" v-if="$can('contact_update')" type="new" btn-text="button.save" @click.prevent="updateContactData" :isLoading="isLoading"/>
                      </div>
                    </div>

                    <div class="card-body" >
                      <RequiredText />
                      <div class="row mb-5">
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group row">
                                <div class="col-9 col-form-label">
                                  <div class="form-check form-check-custom form-check-inline mb-2">
                                    <input  class="form-check-input me-2 h-20px w-20px form-check-success" v-model="contactForm.contactType" value="society" required type="radio" />
                                    <label class="form-check-label me-5">
                                      <div class="text-gray-800"> {{$t("contacts.detail.society")}}</div>
                                    </label>
                                    <input  class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactForm.contactType" value="particular" required type="radio" />
                                    <label class="form-check-label" >
                                      <div class="text-gray-800"> {{$t("contacts.detail.particular")}}</div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex flex-column mt-5 mb-8 fv-row" v-if="contactForm.contactType === 'particular'">
                            <label class="required d-flex align-items-center fs-6  fw-bold mb-2">
                              <span class="">{{$t('user.gender')}}</span>
                            </label>
                            <div class="row">
                              <div class="col-md-10 col-sm-12" >
                                <select required name="gender" class="form-select form-select-solid kt_select2"
                                        v-model="contactForm.gender"  data-control="select2"
                                        data-search="true"
                                        :data-placeholder="$t('user.civility')" >
                                  <option value="female">{{ $t('user.female') }}</option>
                                  <option value="male">{{ $t('user.male') }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4 mb-5">
                            <div :class="contactForm.contactType === 'particular' ? 'col-md-5' : 'col-md-10'">
                              <div class="row">
                                <label class="required mb-1">{{ $t("contacts.lastname") }}</label>
                                <div class="form-group col-md-12">
                                  <input type="text" v-model="contactForm.lastname" class="form-control form-control-solid" :data-placeholder="'contactNamePlaceholder'" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5" v-if="contactForm.contactType === 'particular'">
                              <div class="row">
                                <label class="required mb-1">{{ $t("contacts.firstname") }}</label>
                                <div class="form-group col-md-12">
                                  <input type="text" v-model="contactForm.firstname" class="form-control form-control-solid" :data-placeholder="'contactNamePlaceholder'" required>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row" v-if="contactForm.contactType == 'society'">
                            <Select2
                                :is-required="false"
                                container-class="col-12 col-md-10 mb-5 m-2"
                                :multiple="true"
                                :show-search="true"
                                :selectClass="'form-select form-control-lg form-select-solid'"
                                name="collaborators"
                                :label="$t('contacts.collaborators')"
                                label-class="form-label"
                                :placeholder-text="$t('contacts.select_collaborators')"
                                v-model="contactForm.collaborators"
                            >
                              <option v-for="contact in contactParticuliars" :value="contact.id" :key="contact.id">
                                  {{ contact.lastname }} {{ contact.firstname }}
                              </option>
                            </Select2>
                          </div>
                          <div class="row" v-if="contactForm.contactType == 'particular'">
                            <Select2
                                :is-required="false"
                                container-class="col-12 col-md-10 mb-5 m-2"
                                :multiple="false"
                                :show-search="true"
                                :selectClass="'form-select form-control-lg form-select-solid'"
                                name="company"
                                :label="$t('contacts.company')"
                                label-class="form-label"
                                :placeholder-text="$t('contacts.select_company')"
                                v-model="contactForm.company_id"
                            >
                              <option v-for="contact in contactSocieties" :value="contact.id" :key="contact.id">
                                  {{ contact.lastname }} {{ contact.firstname }}
                              </option>
                            </Select2>
                         </div>

                          <div class="row" v-if="contactForm.contactType == 'particular'">
                            <Select2
                      
                              container-class="col-12 col-md-10 mb-5 m-2"
                              :multiple="false"
                              :show-search="true"
                              selectClass="form-control form-select form-select-solid rounded-end-0 border-start"
                              :name="`post`"
                              :label="$t('contacts.post')"
                              :placeholderText="$t('contacts.post')"
                              v-model="contactForm.post_id"
                              :IsInputGroup="true"
                              :is-required="false"
                              icon="bi bi-plus"
                              :clickAddon="openPostModal">
                              <option v-for="post in posts" :key="post?.id" :value="post?.id">{{ post?.title }}</option>
                             </Select2>

                          </div>
                          <div class="form-group row mt-4">
                            <div class="col-9 col-form-label">
                              <div class="form-check form-check-custom form-check-inline">
                                <input :id="'customer'+id" class="form-check-input me-2 h-20px w-20px form-check-success" v-model="contactForm.customer" value="society"  type="checkbox" />
                                <label class="form-check-label me-5" :for="'customer'+id">
                                  <div class="text-gray-800"> {{$t("contacts.customer")}}</div>
                                </label>
                                <input :id="'supplier'+id" class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactForm.supplier" value="particular"  type="checkbox" />
                                <label class="form-check-label" :for="'supplier'+id">
                                  <div class="text-gray-800"> {{ $t('contacts.supplier') }}</div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 mt-20">
                          <div class="form-group row">
                            <div class="col-lg-8">
                              <div class="image-input image-input-outline" data-kt-image-input="true" :style="'background-image: url(' + contactForm.previewAvatar +')'">
                                <div class="image-input-wrapper w-150px h-150px rounded-circle" :style="'background-image: url(' + contactForm.previewAvatar +')'"></div>
                                <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" :title="$t('button.edit')">
                                  <i class="bi bi-pencil-fill fs-7"></i>
                                  <input v-on:change="onFileChange" type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                  <input type="hidden" name="avatar_remove" />
                                </label>
                                <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow remove" v-on:click="removeFile" title="Remove avatar">
                                  <i class="bi bi-x fs-2"></i>
                                </span>
                              </div>
                              <div class="form-text">{{ $t('contacts.type_file') }}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                        <div class="col">
                          <div class="fv-row mb-2">
                            <label class="fs-6 fw-bold form-label mt-3">
                              <span class="">{{ $t('contacts.email') }} :</span>
                            </label>
                            <input type="email" class="form-control form-control-solid" v-model="contactForm.email" />
                          </div>
                        </div>
                        <div class="col">
                          <div class="fv-row mb-2">
                            <label class="fs-6 fw-bold form-label mt-3">
                              <span>{{ $t('contacts.phone') }} :</span>
                            </label>
                            <input  :id="'kt_tagify_telephone_' + id" class="form-control tagify " v-model="contactForm.phones" type="text"/>
                            <small :id="'valueHelpBlock' + id" class="form-text text-warning"> {{$t('contacts.placeholder.tag_input_text')}}   </small>
                          </div>
                        </div>
                      </div>

                      <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2" v-show="contactForm.contactType === 'particular'">
                        <div class="col">
                          <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold form-label mt-3">
                              <span class="">{{ $t('contacts.birthday') }} :</span>
                            </label>
                            <input  v-model="contactForm.birthday" class="form-control form-control-solid" id="kt_hbd_view"/>
                          </div>
                        </div>
                        <div class="col">
                          <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold form-label mt-3">
                              <span>{{ $t('contacts.website') }} :</span>
                            </label>
                            <input type="url" :placeholder="$t('contacts.placeholder.website')" class="form-control form-control-solid" v-model="contactForm.website" />
                          </div>
                        </div>
                      </div>

                      <div class="row mb-5">
                        <div class="col-sm-12 col-md-6" v-if="contactForm.contactType === 'society'">
                          <label class="fs-6 fw-bold form-label">
                            <span>{{ $t('contacts.website') }}</span>
                            <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Enter the contact's phone number (optional)."></i>
                          </label>
                          <input type="text" class="form-control form-control-solid" v-model="contactForm.website" />
                        </div>
                        <div class="col-sm-12" :class="contactForm.contactType === 'society' ? 'col-md-6' : 'col-md-12'">
                          <label class="d-flex align-items-center fs-6  fw-bold mb-2">
                            <span class="">{{$t('auth.currency')}}</span>
                          </label>
                          <select name="currency" class="form-select form-select-solid kt_select2"
                                  v-model="contactForm.currency_id"  data-control="select2"
                                  data-search="true"
                                  :data-placeholder="$t('user.select_currency')" >
                            <option v-for="currency in getCurrencies" :key="currency.id" :value="currency.id">{{ currency.name + ' (' +  currency.symbol + ')'}}</option>
                          </select>
                        </div>
                      </div>
                      
                      <div class="separator mb-6"></div>
                      <div style="float: right;" class="mb-5">
                        <Button btn-class="btn-sm" v-if="$can('contact_update')" type="new" btn-text="button.save" @click.prevent="updateContactData" :isLoading="isLoading"/>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="tab-pane fade show" id="kt_ecommerce_customer_address" role="tabpanel">
                <div  v-if="empty_view">
                  <div class="card card-min pt-4 mb-6 mb-xl-9" v-if="contact.addresses && contact.addresses.length">
                    <div class="card-header border-0">
                      <div class="card-title">
                        <h2 class="fw-bolder mb-0">{{ $t('contacts.address') }}</h2>
                      </div>
                      <div class="card-toolbar">
                        <Button btn-class="btn-sm" v-if="$can('contact_update')" type="new" btn-text="contacts.add_new_address" @click.prevent="openAddressModal('create')"/>
                      </div>
                    </div>
                    <div id="kt_customer_view_payment_method" class="card-body pt-0">
                      <div  v-for="(address,key) in contact.addresses" :key="key">
                        <div class="py-0 px-5" data-kt-customer-payment-method="row" style="background-color: #0072CE0D; margin-bottom: 10px; border-radius: 8px;">
                          <div class="py-1 d-flex flex-stack flex-wrap">
                            <div class="d-flex align-items-center collapsible rotate" data-bs-toggle="collapse" :href="'#kt_customer_view_payment_method_' + key" role="button" aria-expanded="false" :aria-controls="'kt_customer_view_payment_method_' + key">
                              <div class="me-3 rotate-90">
                                <span class="svg-icon svg-icon-3">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                                  </svg>
                                </span>
                              </div>
                              <div class="me-3">
                                <div class="d-flex align-items-center">
                                  <div class="text-gray-800 fw-bolder me-2" v-if="address.address">{{ address.address }}</div>
                                  <div class="text-gray-800 fw-bolder" v-if="address.city"> {{ address.city?.name }}</div>
                                  <div class="text-gray-800 fw-bolder" v-if="address.country">,  {{ address.country?.name  }}</div>
                                  <span v-if="address.default" class="badge badge-light-primary mx-10" >{{ $t('contacts.default') }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex my-3 ms-9">
                              <button class="btn btn-icon btn-active-light-primary w-30px h-30px me-3" v-if="$can('contact_update')" @click.prevent="openAddressModal('edit', address)">
                              <span data-bs-toggle="tooltip" data-bs-trigger="hover" :title="$t('button.edit')">
                                <i class="fa fa-edit"></i>
                              </span>
                              </button>
                              <button v-show="!address.default" v-if="$can('contact_update')" @click="contactSetDefaultAddress(address.id)" class="btn btn-icon btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="tooltip" :title="$t('contacts.set_default_adress')" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                <span class="svg-icon svg-icon-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="currentColor" />
                                  <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="currentColor" />
                                </svg>
                              </span>
                              </button>
                              <button  @click="dropAddress(address.id)"  v-if="$can('contact_update')" class="btn btn-icon btn-active-light-primary w-30px h-30px" data-bs-toggle="tooltip" :title="$t('button.delete')" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                <i class="fa fa-trash-alt"></i>
                              </button>
                              <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-150px py-3" data-kt-menu="true">
                                <div class="menu-item px-3">
                                  <a href="#" class="menu-link px-3" data-kt-payment-mehtod-action="set_as_primary">Set as Primary</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div :id="'kt_customer_view_payment_method_' + key" :class="key === 0 ? 'show' : ''" class="collapse  fs-6 ps-10" data-bs-parent="#kt_customer_view_payment_method">
                            <div class="separator"></div>
                            <div class="d-flex flex-wrap py-5">
                              <div class="flex-equal me-5">
                                <table class="table table-flush fw-bold gy-1">

                                  <tr>
                                    <td class="text-gray-800 min-w-125px w-125px">{{ $t('contacts.adress') }}</td>
                                    <td class="text-muted">{{  address.address }}</td>
                                  </tr>
                                  <tr>
                                    <td class="text-gray-800 min-w-125px w-125px">{{ $t('contacts.placeholder.zipcode') }}</td>
                                    <td class="text-muted">{{ address.zipcode }}</td>
                                  </tr>
                                </table>
                              </div>
                              <div class="flex-equal">
                                <table class="table table-flush fw-bold gy-1">
                                  <tr>
                                    <td class="text-gray-800 min-w-125px w-125px">{{ $t('contacts.placeholder.city') }}</td>
                                    <td class="text-muted">{{ address.city_id ? address.city?.name : "" }}</td>
                                  </tr>

                                  <tr>
                                    <td class="text-gray-800 min-w-125px w-125px">{{ $t('auth.country') }}</td>
                                    <td class="text-muted">{{ address?.country_id ?  address.country.name : ""  }}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="card card-min" >
                    <div class="card-body">
                      <div class="card-px text-center ">
                        <h2 class="fs-2x fw-bolder mb-0">{{ $t('contacts.no_address_found') }}</h2>
                        <p class="text-gray-400 fs-4 fw-bold py-7">{{ $t('button.clic') }}
                          <br /></p>
                        <Button btn-class="text-center btn-sm" v-if="$can('contact_update')" type="new" btn-text="contacts.add_new_address" @click.prevent="openAddressModal('create')"/>
                      </div>
                      <div class="text-center pt-8 ">
                        <img src="/assets/media/illustrations/sketchy-1/illustration1.png" alt="" class="mw-100 h-150px h-sm-125px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="kt_ecommerce_customer_stat" role="tabpanel">
                <div class="row">
                  <div class="col-md-6 col-lg-3 mb-5">
                    <div class="card card-body pt-5 me-lg-5" style="height: 80px;">
                      <div class="d-flex flex-stack">
                        <div class="symbol symbol-30px me-4">
                          <div class="symbol-label fs-2 bg-primary text-white">
                            {{contactStats.invoicesNotPaidCount}}
                          </div>
                        </div>
                        <div class="flex-row-fluid flex-wrap">
                          <div class="mb-2">
                            <a class="text-gray-800 fs-6 fw-bolder">
                              {{$t('contacts.no_paid_invoice')}}
                            </a>
                          </div>
                          <h4 class="text-primary">{{formattedLocalAmount(contactStats.invoicesNotPaidTurnover)}} {{currency?.symbol}}</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-3 mb-5">
                    <div class="card card-body pt-5 me-lg-5" style="height: 80px;">
                      <div class="d-flex flex-stack">
                        <div class="symbol symbol-30px me-4">
                          <div class="symbol-label fs-2 bg-success text-white">
                            {{contactStats.invoicesPaidCount}}
                          </div>
                        </div>
                        <div class="flex-row-fluid flex-wrap">
                          <div class="mb-2">
                            <a class="text-gray-800 fs-6 fw-bolder">
                              {{$t('contacts.paid_invoice')}}
                            </a>
                          </div>
                          <h4 class="text-primary">{{formattedLocalAmount(contactStats.invoicesPaidTurnover)}} {{currency?.symbol}}</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-3 mb-5">
                    <div class="card card-body pt-5 me-lg-5" style="height: 80px;">
                      <div class="d-flex flex-stack">
                        <div class="symbol symbol-30px me-4">
                          <div class="symbol-label fs-2 bg-warning text-white">
                            {{contactStats.invoicesValidatedCount}}
                          </div>
                        </div>
                        <div class="flex-row-fluid flex-wrap">
                          <div class="mb-2">
                            <a class="text-gray-800 fs-6 fw-bolder">
                              {{$t('contacts.progress_invoice')}}
                            </a>
                          </div>
                          <h4 class="text-primary">{{formattedLocalAmount(contactStats.invoicesValidatedTurnover)}} {{currency?.symbol}}</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-3 mb-5">
                    <div class="card card-body pt-5" style="height: 80px;">
                      <div class="d-flex flex-stack">
                        <div class="symbol symbol-30px me-4">
                          <div class="symbol-label fs-2 bg-danger text-white">
                            {{contactStats.invoicesCancelledCount}}
                          </div>
                        </div>
                        <div class="flex-row-fluid flex-wrap">
                          <div class="mb-2">
                            <a class="text-gray-800 fs-6 fw-bolder">
                              {{$t('contacts.cancel_invoice')}}
                            </a>
                          </div>
                          <h4 class="text-primary">{{formattedLocalAmount(contactStats.invoicesCancelledTurnover)}} {{currency?.symbol}}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="card">
                  <div class="card-header flex-wrap border-0 pt-6 pb-0">
                    <div class="card-title">
                      <h3 class="card-label">{{ $t('invoice.list') }}</h3>
                    </div>
                    <div class="card-toolbar">
                        <ButtonDropdownFilter
                            :search="false"
                            :options="invoiceStatus"
                            value-field="value"
                            :name-field="locale"
                            v-model="query.status"
                        />
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <table class="table align-middle table-row-bordered fs-6 gy-3 gx-4"
                            id="invoices_datatable">
                        <thead>
                        <tr class="fw-bold fs-6" style="background-color: #F2F3F4;">
                          <th class="min-w-100px">{{ $t('contacts.invoice.number') }}</th>
                          <th class="min-w-100px">{{ $t('contacts.invoice.totalAmount') }}</th>
                          <th class="min-w-100px">{{ $t('contacts.invoice.date') }}</th>
                          <th class="min-w-100px">{{ $t('contacts.invoice.status') }} </th>
                          <th class="min-w-100px">{{ $t('label.action') }}</th>
                        </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show" id="kt_estimate" role="tabpanel">
                <Estimate :contactStats="contactStats?.estimateStatusTurnover" :query="query" :currency="currency?.symbol"/>
              </div>

              <div class="tab-pane fade show" id="kt_pipeline" role="tabpanel">
                <Pipeline :contactStats="contactStats?.opportunitiesTotalByStatus" :query="query" :currency="currency?.symbol"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateAddress
          id="1"
          :get-countries="getCountries"
          :address="form"
          :action="action"
          :contact="contact.id"
          @addressCreated="contactAddressCreated"
          @addressUpdated="contactAddressUpdated"
      >
      </CreateAddress>
    </div>
  </div>
  <PostFormModal @postOffice="selectPosts" id="postCreateModal" ></PostFormModal>
</template>

<script setup>

import {BASE_IMAGE_URL} from "@/services/api";
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useLocales} from "@/composables/locales";
import { useRouter, useRoute } from 'vue-router'
import {useStore} from "vuex";
import CreateAddress from "@/components/CreateAddress";
import AppSubHeader from "@/views/Contact/partials/AppSubViewHeader";
import Select2 from "@/components/Select2.vue";
import {BASE_URL} from "@/services/api";
import {formattedLocalAmount} from "@/mixins/Helpers";
import {useHasAbility} from "@/composables/hasAbility";
import {useLoader} from "@/composables/loader";
import RequiredText from "@/components/RequiredText.vue"
import DateRangePicker from "@/components/DateRangePicker.vue";
import Button from "@/components/Button.vue"
import ButtonDropdownFilter from "@/components/ButtonDropdownFilter.vue";
import {useSwal} from "@/composables/useSwal";
import PostFormModal from "@/components/Post/postModal.vue"
import Estimate from "./components/Estimate";
import Pipeline from "./components/Pipeline";

const router = useRouter()
const route = useRoute()
const store = useStore()
const { can } = useHasAbility()
const { stopLoading } = useLoader()
const {showSwal} = useSwal()

const {currentLanguage, locale, datatableLocaleUrl} = useLocales()

const id = ref(route.params.id)
const table = ref(null)
const title = ref('breadcrumbs.contact.contacts')
const showStat = ref(true)
const breadcrumbs  = ref([
  {
    name : 'breadcrumbs.contact.list',
    path : '/admin/contacts'
  },
  {
    name : 'breadcrumbs.contact.detail',
    path : ''
  },
])
const empty_view = ref(false)
const contact = ref({
  addresses: []
})
const form = ref({
  id : null,
  gender : null,
  lastname: null,
  firstname: null,
  street: null,
  street2: null,
  city_id: null,
  zipcode: null,
  country: null
})
const phones = ref([])
const contactForm = ref({
  id : null,
  gender : '',
  contactType: null,
  lastname: null,
  firstname: null,
  customer: true,
  birthday: null,
  supplier: false,
  phones: [],
  previewAvatar: BASE_IMAGE_URL +'/assets/blank.png',
  email:null,
  website: null,
  currency_id: null,
  post_id : null,
  company_id: null,
  collaborators: []
})
const action = ref('create')
const query = ref({
  status: 'all',
  start_date: '',
  end_date: ''
})
const isLoading = ref(false)


const getCountries = computed(() => store.getters['countries/getCountries'])
const posts = computed(() => store.getters['post/getPost'])
const getContacts = computed(() => store.getters['contacts/getContacts'])
const getCurrencies = computed(() => store.getters['company/getCurrencies'])
const invoiceStatus = computed(() => store.getters['invoices/invoiceStatus'])
const paymentPaidStatus = computed(() => invoiceStatus.value.find(el => el.value == 'PAID')?.value || '')
const contactStats = computed(() => store.getters['contacts/getContactStats'])
const currency = computed(() => store.getters['company/getCompanyCurrency'])
const contactSocieties = computed(() => getContacts.value.filter((contact) => contact.contactType == 'society'))
const contactParticuliars = computed(() => getContacts.value.filter((contact) => contact.contactType == 'particular' && (contact.company_id == null || contact.company_id == route.params.id)))


watch(() => query.value.status, (newValue) => {
  if(newValue){
    table.value?.ajax.url(`${BASE_URL}/get/invoices/datatables?status=${query.value.status}&contact=${route.params.id}&startDate=${query.value.start_date}&endDate=${query.value.end_date}`).load()
  }
})


watch(() => query.value.start_date, (newValue) => {
  if(newValue && query.value.start_date && query.value.end_date){
    loadContactStats()
    table.value?.ajax.url(`${BASE_URL}/get/invoices/datatables?status=${query.value.status}&contact=${route.params.id}&startDate=${query.value.start_date}&endDate=${query.value.end_date}`).load()
  }
}, { deep: true })

watch(() => locale.value, (value) => {
  nextTick(() => {
    fetchDatas()
  })
}, { deep: true })

watch(()=>contactForm.value.phones, (value) => {
  if(value){
    phones.value.addTags(contactForm.value.phones)
  }
})

watch(() => route.params.id, async (value, oldValue) => {
  if (value !== oldValue && route.name === 'contact-view-path') {
    await loadContactData();
    await loadContactStats();
    fetchDatas();
  }
});

watch(() => contactForm.value.contactType, (value) => {
  if (value) {
   nextTick(() => {
     initSelect2()
    })
  }
})

onMounted(() => {
  reloadPost()
  tagifyTelephone()
  KTImageInput.init();
  KTApp.initSelect2()
  initSelect2()

  let today = new Date() ;
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  $("#kt_hbd_view").flatpickr({
    dateFormat: "d-m-Y",
    maxDate: yesterday
  });
  loadCountries()
  store.dispatch('invoices/loadInvoiceStatus')
  store.dispatch('company/generalInformation')
  loadCities()
  loadContacts()
  loadCurrencies()
  loadContactData()
  empty_view.value = true
  loadContactStats()
  fetchDatas()
  redirectDatatableAction()
})


const openStat = () => {
  showStat.value = true
}

const openOtherStat = () => {
  showStat.value = false
}

function selectPosts(id) {
  contactForm.value.post_id =id
  
}
function getInvoiceStatusName(status) {
  const {color, fr, en} = invoiceStatus.value.find(el => el.value === status) || ''
  return {color, fr, en}
}

function fetchDatas() {
  const AUTH_TOKEN = Ls.get('auth.token')

  table.value = $("#invoices_datatable").DataTable({
    destroy: true,
    responsive: !0,
    searchDelay: 500,
    processing: !0,
    serverSide: !0,
    pageLength: Ls.get('pageLength') ?? 5,
    lengthMenu: [5, 10, 25, 50, 75, 100 ],
    order: [[ 1, 'asc']],
    ajax: {
      url: BASE_URL + `/get/invoices/datatables?status=${query.value.status}&contact=${route.params.id}&startDate=${query.value.start_date}&endDate=${query.value.end_date}`,
      type: "GET",
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", "Bearer " + AUTH_TOKEN);
      }
    },
    columns: [
      { data: "invoice_number" }, { data: "due_amount" }, { data: "date" }, { data: "invoice_status" }, { data: "Actions", responsivePriority: -1 }
    ],
    columnDefs: [

      {
        targets: -5,
        order: "desc",
        className: "dt-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="'+ e.id +'">' + t + "</span>";
        },
      },

      {
        targets: -4,
        order: "desc",
        className: "dt-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="' + e.id + '">' + formattedLocalAmount(t) + ' (' + e.currency + ')</span>';
        },
      },

      {
        targets: -3,
        order: "desc",
        className: "dt-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="'+ e.id +'">' + t + "</span>";
        },
      },

      {
        targets: -2,
        orderable: false,
        className: "dt-center" ,
        render: function (t, a, e, l) {
          let status = getInvoiceStatusName(t)
          const locale = i18n.global.locale.value
          let color = status.color
          status = status[locale]
          return '<span class="badge badge-light-' + color +'" style="font-size:13px; margin:3px; padding:5px"><span style="margin-left: 5px" class="valueRow' + l.row + ' label label-success label-inline font-weight-bolder mr-2 edit_value_btn">' + status + '</span></span>'
        },
      },


      {
        width: "20%",
        targets: -1,
        "className": "dt-center",
        title: "Actions",
        orderable: !1,
        render: (t, a, e, l) => {
          let render=""
          if(can('invoice_view')){
            render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon show_btn" title="'+ i18n.global.t('button.show') +'"><i  data-id="'+ e.id +'" class="la la-eye fs-2"></i></a>'
          }
          if(can('invoice_update')) {
            if(e.invoice_status == 'DRAFT'){
              render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon edit_btn" title="'+ i18n.global.t('button.edit') +'"><i  data-id="'+ e.id +'" class="la la-edit fs-2"></i></a>'
            }
          }

          return render
        },
      },

    ],
    language: {
      url: datatableLocaleUrl.value,
    }
  });

}

function redirectDatatableAction(){
  $(document).on("click", "#invoices_datatable .show_btn", (e) => {
    router.push({ name: 'invoices-view', params: { id: e.target.getAttribute('data-id') } })
  });

  $(document).on("click", "#invoices_datatable .edit_btn", (e) => {
    router.push({ name: 'invoices-edit', params: { id: e.target.getAttribute('data-id') } })
  });
}

const loadContactStats = async () => {
  try {
    if(query.value.start_date && query.value.end_date){
      await store.dispatch('contacts/loadContactStats', {id: route.params.id, startDate: query.value.start_date, endDate: query.value.end_date})
    }
  }catch (e) {
  }
}

const loadCountries = async () => {
  try {
    await store.dispatch('countries/loadCountries')
  }catch (e) {
  }
}

function reloadPost() {
 store.dispatch('post/loadPosts')
}

function openPostModal() {
 $('#postCreateModal').modal('toggle');
}

const loadCities = async () => {
  try {
    await store.dispatch('cities/loadCities')
  }catch (e) {
  }
}

const loadContacts = async () => {
  try {
    await store.dispatch('contacts/loadContacts')
  }catch (e) {
  }
}

const loadCurrencies = async () => {
  try {
    await store.dispatch('company/loadCurrencies')
  }catch (e) {
  }
}

function initSelect2() {
  $("body .kt_select2").select2()
  $('body select[data-control="select2"]').select2()

  $("body .kt_select2").change((e) => {
    switch (e.target.name) {
      case 'contact':
        router.push('/admin/contact/' + e.target.value + '/view')
        break;
      case 'gender' :
        contactForm.value.gender = e.target.value
        break;
      case 'currency' :
        contactForm.value.currency_id = e.target.value
        break;
      default:
        break;
    }
  });

}
function onFileChange(e) {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length)
    return;
  contactForm.value.previewAvatar = files[0]
}
function removeFile(e) {
  contactForm.value.previewAvatar = BASE_IMAGE_URL +'/assets/blank.png'
  contactForm.value.avatar = null
}


function handleRangeDate (range) {
  query.value.start_date = range.start_date
  query.value.end_date = range.end_date
}

async function loadContactData(){
  KTImageInput.init();

  store.dispatch('contacts/getContact',route.params.id).then((res) => {
    contact.value = res.data.contact
    contactForm.value = {
      id:  res.data.contact.id,
      avatar:   null,
      avatar_url:  res.data.contact.avatar_url ?  res.data.contact.avatar_url : null,
      gender:  res.data.contact.gender ?  res.data.contact.gender : null,
      lastname:  res.data.contact.lastname,
      firstname:  res.data.contact.firstname,
      birthday:  res.data.contact.birth ?  res.data.contact.birth : null,
      phones:  res.data.contact.phones?.split(','),
      email:  res.data.contact.email ?  res.data.contact.email : null,
      contactType:  res.data.contact.contactType ,
      customer:  res.data.contact.customer ,
      supplier:  res.data.contact.supplier ,
      website:  res.data.contact.website ?  res.data.contact.website : null,
      previewAvatar:  res.data.contact.avatar_url ?? '',
      currency_id: res.data.contact?.currency_id,
      post_id: res.data.contact?.post_id,
      company_id: res.data.contact?.company_id,
      collaborators: res.data.contact?.collaborators?.map(item => item.id)
    }
  })

}
async function updateContactData(){
  let response = {}

  isLoading.value = true

  if(contactForm.value.contactType == 'society'){
    contactForm.value.gender = ''
    contactForm.value.firstname = ''
    contactForm.value.birthday = null
  }

  try{
    response = await store.dispatch('contacts/updateContact',contactForm.value)
    contact.value = {...response.data.contact,addresses: contact.value.addresses}

    if (contactForm.value.previewAvatar && contactForm.value.previewAvatar instanceof File) {
      let avatarData = new FormData()
      avatarData.append('avatar', contactForm.value.previewAvatar);
      avatarData.append('contact_id', contactForm.value.id);
      let avatarResponse = await store.dispatch('contacts/uploadAvatar',avatarData)
      contact.value.avatar_url = avatarResponse.data.avatar
    }
    KTApp.initSelect2()
    initSelect2()
    window.toastr['success'](i18n.global.t("notification.contact_updated"))
    isLoading.value = false
    return true

  }catch(err){
    isLoading.value = false
  }
}
function setAddressValue(address){
  form.value = {
    id : address?.id || null,
    gender : address?.gender || null,
    lastname: address?.lastname || null,
    firstname: address?.firstname || null,
    street: address?.address || null,
    street2:address?.addressComp || null,
    city_id: address?.city_id || null,
    zipcode: address?.zipcode || null,
    country: address?.country_id || null,
  }
}




function openAddressModal(act, address = null){
  action.value = act
  setAddressValue(address)

  $('#contactAddressModal1').modal('toggle')
}
function contactSetDefaultAddress(id){
  showSwal(
      '',
      i18n.global.t('contacts.set_default_adress')
  )?.then( async (result) => {
      if (result.value) {
        await store.dispatch('contacts/setDefaultAddress',{
          contact: contact.value.id,
          address: id
        })

        contactAddressUpdated({id:id, default: true})

        window.toastr['success'](i18n.global.t("notification.contact_default_address_seted"))
        store.dispatch('contacts/getContact',route.params.id).then((res) => contact.value = res.data.contact)
      }
  })
}
function dropAddress(id) {
  showSwal(
      i18n.global.t('swal.title'),
      i18n.global.t('swal.address')
  )?.then( async (result) => {
      if (result.value) {
        let response = await store.dispatch('contacts/deleteAddress', {
          contact: contact.value.id,
          address: id
        })

        let defaut = response.data.default

        let addresses = contact.value.addresses || []
        addresses = addresses.filter(address => address.id !== id)

        if (defaut) {
          addresses = addresses.map(address => {
            if (defaut === address.id) {
              address.default = true
            }
            return address
          })
        }
        window.toastr['success'](i18n.global.t("notification.contact_address_delected"))

        contact.value.addresses = addresses
      }
  })
}

function tagifyTelephone(){
  let eTelephone = document.getElementById("kt_tagify_telephone_" + id.value)

  phones.value = new Tagify(eTelephone)
  phones.value
      .on('add', (e) => {
        contactForm.value.phones = phones.value?.value.map(x => x.value)
      })
      .on("edit:updated", (e) => {
        contactForm.value.phones = phones.value?.value.map(x => x.value)
      })
      .on("remove", () => {
        contactForm.value.phones = phones.value?.value.map(x => x.value)
      })
}

function contactAddressCreated(address){
  let addresses = contact.value.addresses || []
  addresses.push(address)
  contact.value.addresses = addresses
}
function contactAddressUpdated(address){
  let addresses = contact.value.addresses || []
  addresses = addresses.map(addr => {
    if(addr.id === address.id){
      return address
    }
    if (address.default){
      addr.default = false
    }
    return addr
  })
  contact.value.addresses = addresses
}
function pad(s) {
  return (s < 10) ? '0' + s : s;
}

</script>

<style scoped>
.card-min{
  height: auto!important;
}

.light-danger {
  color: #f1416c;
  border-color: #fff5f8;
  background-color: #fff5f8;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.light-primary {
  color: #2DA333;
  border-color: #f1faff;
  background-color: #f1faff;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.phone-card {
  background-color: #fff !important;
  color: #646E7B !important;
  border-radius: 10px;
  border: 1px solid #646E7B;
  width: 30px;
  height: 30px;
}

.phone-value {
  color: #222222 !important;
  background-color: #F2F3F4 !important;
}

.img-size {
  width: 110px !important;
  height: 110px !important;
  border-radius: 10px;
}

.contact-card {
  height: 330px !important;
  border-radius: 10px;
}

.contact-card:hover {
  border: 2px solid #0072CE;
  border-radius: 10px;
}

.badge-light-primary {
    color: var(--bs-primary);
    background-color: #0072CE33;
}

.image-input [data-kt-image-input-action=change] {
    left: 80%;
    top: 0;
    margin-top: 15px;
}

.remove {
    position: absolute;
    left: 70%;
    top: 80%;
}

.icon-overlay {
    position: absolute;
    bottom: -10px !important;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    border-radius: 50%;
    padding: 8px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
