import * as types from './mutation-types'

export default {
  [types.ADD_CATEGORIES](state, data) {
    state.categories = data.categories
  },

  [types.ADD_CATEGORY](state, data) {
    state.categories.push(data)
  },
  [types.SET_IMAGE] (state, {id, image}) {
    state.categories = state.categories.map((category) => { if (category.id == id) {category.image = image} return category })
  },
}
