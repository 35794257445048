import { ref, computed } from 'vue'

export function useFormatAmount() {
    const locale = computed(() => i18n.global.locale.value)

    function formatNumber(n) {
        if(locale.value == 'fr'){
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }else{
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
    return { formatNumber }
}