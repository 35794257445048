import * as types from './mutation-types'

export const loadSteps = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/opportunityStates/collection', data).then((response) => {
            commit(types.ADD_STEPS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateStep = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/opportunityState', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateStepStatus = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('set/opportunityState/' + data.id + '/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateStepOrder = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.opportunityState + '/position', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateStepName = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/name', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createStep = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/opportunityState', data).then((response) => {
            commit(types.ADD_STEP, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteStep = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/opportunityState').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

