import * as types from './mutation-types'

export default {
  [types.ADD_PIPELINES](state, data) {
    state.pipelines = data
  },
  [types.LOAD_PIPELINE_STATUS_ENUM](state, data) {
    state.pipelineStatusEnum = data
  },
  [types.LOAD_PIPELINE_PRIORITY_ENUM](state, data) {
    state.pipelinePriorityEnum = data
  },
  [types.LOAD_PIPELINE_HISTORY_CONTEXT_ENUM](state, data) {
    state.pipelineHistoryContextEnum = data
  },
  [types.LOAD_PIPELINE_STATES](state, data) {
    let active = data.filter(item => item.active == true)
    state.pipelineStates = data
    state.activePipelines = active
  },
  [types.SET_CURRENT_PIPELINE](state, data) {
    state.currentPipeline = data.data
  },
  [types.LOAD_PIPELINE_FEELING_ENUM](state, data) {
    state.feelings = data
  },
  [types.LOAD_SALES_PERSONS](state, data) {
    state.salesPersons = data.users
  }
}
