import * as types from './mutation-types'


export const loadPaymentTypes = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/paymentType/collection').then((response) => {
            commit(types.LOAD_PAYMENT_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPaymentStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('payment/status/enum').then((response) => {
            commit(types.LOAD_PAYMENT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPaymentConditions = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/paymentCondition/collection').then((response) => {
            commit(types.LOAD_PAYMENT_CONDITIONS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



