<template>
  <div class="row my-5">
    <div class="col-12 col-md-6 col-lg-3 mb-4" v-for="(status, index) in contactStats" :key="index">
      <div class="card card-body pt-6 h-100">
        <div class="d-flex flex-stack">
          <div class="symbol symbol-40px me-4">
            <div class="symbol-label fs-2" :class="status.name == 'CANCELLED' ? 'bg-secondary' : 'bg-'+filterStatus(status.name)[0]?.color">
              <i class="fas fa-file-alt fs-2" style="color: white;"></i>
            </div>
          </div>
          <div class="d-flex align-items-center flex-row-fluid flex-wrap">
            <div class="flex-grow-1 me-2">
              <a class="text-gray-800 fs-6 fw-bold">
                {{$t('devis.devis') + ' ' + filterStatus(status.name)[0]?.[locale]}}
              </a>
              <span class="text-muted fw-bold d-block fs-7">{{status?.count}}</span>
            </div>
            <div>{{formattedLocalAmount(status?.amount)}} {{currency}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Card :title="$t('estimate.list')" containerClass="mb-5">
    <template #cardToolbar>
      <ButtonDropdownFilter
          :search="false"
          :options="Object.values(estimateStatus)"
          value-field="value"
          :name-field="locale"
          v-model="status"
      />
      <Button type="new" btn-class="btn-sm me-2" btn-text="estimate.create" @click="openCreateView" ></Button>
    </template>
    <template #cardBody>
      <div class="table-responsive row">
        <table class="table align-middle table-row-bordered fs-6 gy-3 gx-4"
                  id="estimates_datatable">
            <thead class="mb-5">
              <tr class="fw-bold fs-6" style="background-color: #F2F3F4;">
                <th class="min-w-100px text-center">{{ $t('estimate.label.number') }}</th>
                <th class="min-w-100px text-center">{{ $t('estimate.label.date') }}</th>
                <th class="min-w-100px text-center">{{ $t('estimate.label.status') }} </th>
                <th class="min-w-100px text-center">{{ $t('estimate.label.totalAmount') }}</th>
                <th class="w-100px text-start">{{ $t('label.action') }}</th>
              </tr>
            </thead>
        </table>
      </div>
    </template>
  </Card>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import i18n from "@/plugins/i18n";
import Ls from "@/services/ls";
import {BASE_URL} from "@/services/api";
import {formattedLocalAmount} from "@/mixins/Helpers";
import {useRouter, useRoute} from "vue-router";
import {useLocales} from "@/composables/locales";
import {useHasAbility} from "@/composables/hasAbility";
import Card from "@/Modules/CRM/components/Card";
import Button from "@/components/Button.vue";
import {useSwal} from "@/composables/useSwal";
import ButtonDropdownFilter from "@/components/ButtonDropdownFilter.vue";

const router = useRouter()
const route = useRoute()

const store = useStore()
const { can } = useHasAbility()
const {locale, datatableLocaleUrl} = useLocales()
const {showSwal} = useSwal()

const props = defineProps({
  contactStats: Object,
  query: Object,
  currency: String
})

const table = ref()

const status = ref("all")

const estimateStatus = computed(() => store.getters['estimates/estimateStatus'])

watch(() => locale.value, (value) => {
  nextTick(() => fetchDatas())
}, { deep: true })

watch(() => status.value, (newValue) => {
  table.value.ajax.url(`${BASE_URL}/get/estimates/datatables?status=${status.value}&startDate=${props.query.start_date}&endDate=${props.query.end_date}&contact=${route.params.id}`).load()
})

watch(() => [props.query.start_date, props.query.end_date], (newValue) => {
  table.value.ajax.url(`${BASE_URL}/get/estimates/datatables?status=${status.value}&startDate=${props.query.start_date}&endDate=${props.query.end_date}&contact=${route.params.id}`).load()
})

function fetchDatas() {
  const AUTH_TOKEN = Ls.get('auth.token')

  table.value = $("#estimates_datatable").DataTable({
    destroy: true,
    responsive: !0,
    searchDelay: 500,
    processing: !0,
    serverSide: !0,
    pageLength: Ls.get('pageLength') ?? 5,
    lengthMenu: [5, 10, 25, 50, 75, 100 ],
    order: [[ 1, 'desc']],
    ajax: {
      url: BASE_URL + `/get/estimates/datatables?status=${status.value}&startDate=${props.query.start_date}&endDate=${props.query.end_date}&contact=${route.params.id}`,
      type: "GET",
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", "Bearer " + AUTH_TOKEN);
      }
    },
    columns: [
      { data: "estimate_number" }, { data: "date" }, { data: "status" }, { data: "total" }, { data: "Actions", responsivePriority: -1 }
    ],
    columnDefs: [

      {
        targets: -5,
        order: "desc",
        className: "dt-center text-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="'+ e.id +'">' + t + "</span>";
        },
      },

      {
        targets: -4,
        order: "desc",
        className: "dt-center text-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="'+ e.id +'">' + t + "</span>";
        },
      },

      {
        targets: -3,
        orderable: false,
        className: "dt-center text-center clickable" ,
        render: function (t, a, e, l) {
          let status = getEstimateStatusName(t)
          const locale = i18n.global.locale.value
          let color = status.color
          status = status[locale]
          return '<span class="badge badge-light-' + color +'" style="font-size:13px; margin:3px; padding:5px"><span style="margin-left: 5px" class="valueRow' + l.row + ' label label-success label-inline font-weight-bolder mr-2 edit_value_btn">' + status + '</span></span>'
        },
      },

      {
        targets: -2,
        order: "desc",
        className: "dt-center text-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="'+ e.id +'">' + formattedLocalAmount(t) + ' ' + e.currency + "</span>";
        },
      },

      {
        width: "15%",
        targets: -1,
        "className": "dt-center text-start",
        title: "Actions",
        orderable: !1,
        render: (t, a, e, l) => {
          let render=""
          if(can('estimate_view')){
            render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon show_btn" title="'+ i18n.global.t('button.show') +'"><i  data-id="'+ e.id +'" class="la la-eye fs-2"></i></a>'
          }
          if(can('estimate_update') && (e.status == 'DRAFT'  || e.status == 'PENDING')) {
              render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon edit_btn" title="'+ i18n.global.t('button.edit') +'"><i  data-id="'+ e.id +'" class="la la-edit fs-2"></i></a>'
          }
          if(can('estimate_delete') && e.status == 'DRAFT'){
            render += '<a href="javascript:;" data-row="'+ l.row +'" data-id="'+ e.id +'" class="btn btn-sm btn-clean btn-icon delete_btn" title="'+ i18n.global.t('button.delete') +'"><i data-row="'+ l.row +'" data-id="'+ e.id +'" class="la la-trash fs-2"></i></a>'
          }

          return render
        },
      },

    ],
    language: {
      url: datatableLocaleUrl.value,
    }
  });

  $(document).on("click", "#estimates_datatable .show_btn", (e) => {
    router.push({ name: 'estimates-view', params: { id: e.target.getAttribute('data-id') } })
  });

  $(document).on("click", "#estimates_datatable .edit_btn", (e) => {
    router.push({ name: 'estimates-edit', params: { id: e.target.getAttribute('data-id') } })
  });

  $(document).on("click", "#estimates_datatable .delete_btn", (e) => {
    deleteEstimate(e.target.getAttribute('data-id'))
  });
}

function getEstimateStatusName(status) {
  const {color, fr, en} = estimateStatus.value.find(el => el.value === status) || ''
  return {color, fr, en}
}

function openCreateView(){
  router.push({ name: 'estimates-create' })
}

function deleteEstimate(id){
  showSwal(
        i18n.global.t('crm.estimate.swal.deleted'),
        i18n.global.t('crm.estimate.swal.deleted_text')
    ).then( async (result) => {
        if (result.value) {
            store.dispatch('estimates/deleteEstimate', id)
                .then(() => {
                    window.toastr['success'](i18n.global.t('notification.estimate_deleted'))
                    table.value.ajax.reload()
                    closeModal()
                })
                .catch(() => {
                    
                })
        }
    })
}

function loadData() {
Promise.all([
  store.dispatch('estimates/loadEstimateStatus'),
  store.dispatch('contacts/loadContacts'),
  fetchDatas()
])
}

function filterStatus(value){
  let statistic = estimateStatus.value.filter(status => status.value === value)
  return statistic
}

onMounted( () => {
loadData()
})
</script>